import { Component } from "react";
import PropTypes from "prop-types";
import { linksConstants } from "../../../constants/linksConstants";
import { Button } from "../buttons/Button";

export default class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: "",
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo });
    this.setState({ errorInfo });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <main className="AppErrorBoundary">
          <article className="AppErrorBoundary__contentWrapper">
            <h4 className="AppErrorBoundary__message">Sorry, something went wrong.</h4>
            <Button
              className="AppErrorBoundary__reloadPageButton"
              onClick={() => window.location.reload()}
            >
              Reload the page
            </Button>
            <a href={linksConstants.ALL_USE_CASES} className="AppErrorBoundary__returnHome">
              <Button className="AppErrorBoundary__returnHome__button" variant={"outlined"}>
                Return home
              </Button>
            </a>
          </article>
        </main>
      );
    }
    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
