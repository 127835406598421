import { demystConsoleApi } from "./api";

export const apiTemplatesCatalogApi = {
  async getAPITemplates(params, configuration) {
    return await demystConsoleApi.get("/api_templates", {
      params,
      withCredentials: true,
      ...configuration,
    });
  },

  async getAPITemplate(apiTemplateId, configuration) {
    return await demystConsoleApi.get(`/api_templates/${apiTemplateId}`, {
      withCredentials: true,
      ...configuration,
    });
  },

  async getFeaturedAPITemplates() {
    return await demystConsoleApi.get("api_templates?featured=true", {
      withCredentials: true,
    });
  },

  async getRelatedRecipes(apiTemplateId, configuration) {
    return await demystConsoleApi.get(`/api_templates/${apiTemplateId}/related.json`, {
      withCredentials: true,
      ...configuration,
    });
  },

  async cloneAPITemplate({ apiTemplateId, name, regionId, description }) {
    return await demystConsoleApi.post(
      `api_templates/${apiTemplateId}/clones`,
      {
        clone: { name, region_id: regionId, description },
      },
      {
        withCredentials: true,
      },
    );
  },
};
