import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils/serializeError";
import { productsCatalogApi } from "../../api/productsCatalogApi";
import Axios from "axios";

const initialState = {
  products: {
    fetchStatus: "IDLE",
    error: null,
    data: {
      entities: {},
      ids: [],
    },
    searchParams: {
      providers_page: 1,
      search: "",
      sort: "",
      diligence_tags: [],
      direction: "",
      categories: [],
      regions: [],
      data_source: "",
      tags: [],
    },
    totalPages: 1,
    totalEntries: 0,
  },
  showFilters: false,
};

// --- THUNKS START ---
const fetchProducts = createAsyncThunk(
  "catalog/fetchProducts",
  async (payload, thunkAPI) => {
    const { productsCatalog } = thunkAPI.getState();

    const source = Axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    // validate all params from qs;
    const params = {
      search: payload.search || initialState.products.searchParams.search,
      sort: payload.sort || initialState.products.searchParams.sort,
      diligence_tags: payload.diligence_tags || initialState.products.searchParams.diligence_tags,
      direction: payload.direction || initialState.products.searchParams.direction,
      categories: payload.categories || initialState.products.searchParams.categories,
      regions: payload.regions || initialState.products.searchParams.regions,
      data_source: payload.data_source || initialState.products.searchParams.data_source,
      page: payload.providers_page || initialState.products.searchParams.providers_page,
      tags: payload.tags || initialState.products.searchParams.tags,
      created_by_me: payload.created_by_me,
      created_by_organization: payload.created_by_organization,
    };

    // Data should be fetched only when params are different from what's already in Redux
    // or if there are no products
    const shouldFetch =
      Object.entries(params).some(entry => {
        return entry[1]?.toString() !== productsCatalog.products.searchParams[entry[0]]?.toString();
      }) || productsCatalog.products.data.ids.length === 0;

    if (shouldFetch) {
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(productsCatalogActions.setProductsLoading());
        const { created_by_me, created_by_organization, ...newParams } = params;
        thunkAPI.dispatch(
          productsCatalogActions.setProductsSearchParams({
            ...newParams,
            ...(created_by_me === true && { created_by_me: true }),
            ...(created_by_organization === true && { created_by_organization: true }),
          }),
        );
      }
      const { data } = await productsCatalogApi.getProducts(params, {
        cancelToken: source.token,
      });
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(productsCatalogActions.setProducts(data));
      }
    }
  },
  {
    serializeError: serializeError,
  },
);

export const productsCatalogThunks = {
  fetchProducts,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "productsCatalog",
  initialState,
  reducers: {
    setProducts(state, action) {
      const { providers, pagination } = action.payload;
      // set data
      state.products.data.entities = {};
      state.products.data.ids = [];
      providers.forEach(product => {
        state.products.data.entities[product.id] = product;
        state.products.data.ids.push(product.id);
      });
      // set pagination
      state.products.totalPages = pagination.total_pages;
      state.products.totalEntries = pagination.total_entries;
    },
    setSearchString(state, action) {
      state.products.searchString = action.payload;
    },
    setProductsSearchParams(state, action) {
      state.products.searchParams = action.payload;
    },
    updateProductsSearchParams(state, action) {
      const { param, value } = action.payload;
      state.products.searchParams[param] = value;
    },
    setProductsLoading(state) {
      state.products.fetchStatus = "PENDING";
      state.products.error = null;
    },
    setShowFilters(state, action) {
      state.showFilters = action.payload;
    },
    setTags(state, action) {
      state.tags = action.payload;
    },
    setTagIds(state, action) {
      state.tagIds = action.payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: {
    [fetchProducts.fulfilled]: state => {
      state.products.fetchStatus = "SUCCESS";
      state.products.error = null;
    },
    [fetchProducts.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        return;
      }
      state.products.fetchStatus = "FAILED";
      state.products.error = action.error;
    },
  },
});

export const productsCatalogActions = {
  ...actions,
};

export default reducer;
