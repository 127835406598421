import { Provider } from "react-redux";
import { generateStore } from "./store";
import { createContext, useContext, useEffect, useState } from "react";
import usePathChange from "../utils/hooks/usePathChange";
import { userApi } from "../api/userApi";

let serverSideStore;

const Redux = ({ children, initialReduxState }) => {
  if (initialReduxState && !serverSideStore) serverSideStore = generateStore(initialReduxState);

  return (
    <Provider store={serverSideStore ? serverSideStore : require("./store").default}>
      {children}
    </Provider>
  );
};

const UserContext = createContext({});
const User = ({ children }) => {
  const [user, setUser] = useState(false);
  const pathname = usePathChange();
  useEffect(() => {
    (async () => {
      const result = await userApi.getUserData().catch(() => {});
      setUser(result?.data || {});
    })();
  }, [pathname]);
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const user = useContext(UserContext);
  return user;
};

export default {
  Redux,
  User,
};
