import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { operationalDashboardApi } from "../../api/operationalDashboardApi";
import { userThunks } from "../user/userSlice";
import { serializeError } from "../../utils/serializeError";
import { format, sub } from "date-fns";
import Axios from "axios";
import { notEmptyArray } from "../../utils/helpers";

const initialState = {
  startDate: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd"),
  endDate: format(new Date(), "yyyy-MM-dd"),
  useCases: {
    entities: {},
    liveUseCasesIds: [],
    demoUseCasesIds: [],
  },
  dashboard: {
    fetchStatus: "IDLE",
    error: null,
    pagination: {
      totalPages: 10,
      totalAPIs: 10,
      use_cases_page: 1,
      sort: "",
      direction: "",
    },
    requestId: null,
  },
};

// --- THUNKS START ---
const fetchEvaluationUseCases = createAsyncThunk(
  "evaluationUseCases/fetchEvaluationUseCases",
  async (payload, thunkAPI) => {
    const { evaluationUseCases } = thunkAPI.getState();

    const source = Axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });

    // validate all params from qs (currently, it's only pagination related so for now
    // params === pagination);
    const queryStringParams = {
      page: payload?.use_cases_page || initialState.dashboard.pagination.use_cases_page,
      sort: payload?.sort || initialState.dashboard.pagination.sort,
      direction: payload?.direction || initialState.dashboard.pagination.direction,
    };

    // Data should be fetched only when params are different from what's already in Redux
    // or if there are no use cases
    const shouldFetch =
      payload.forceFetch ||
      Object.entries(queryStringParams).some(entry => {
        return (
          entry[1]?.toString() !== evaluationUseCases.dashboard.pagination[entry[0]]?.toString()
        );
      }) ||
      [
        ...evaluationUseCases.useCases.liveUseCasesIds,
        ...evaluationUseCases.useCases.demoUseCasesIds,
      ].length === 0 ||
      evaluationUseCases.dashboard.fetchStatus === "IDLE" ||
      evaluationUseCases.dashboard.fetchStatus === "FAILED";

    if (shouldFetch) {
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(evaluationUseCasesActions.setUseCasesDashboardLoading());
        thunkAPI.dispatch(
          evaluationUseCasesActions.setUseCasesDashboardSearchParams(queryStringParams),
        );
      }

      const { data, headers } = await operationalDashboardApi.getEvaluationUseCases(
        queryStringParams,
        {
          cancelToken: source.token,
        },
      );

      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(
          evaluationUseCasesActions.setUseCases({
            data,
            pagination: JSON.parse(headers["x-pagination"]),
          }),
        );
      }
    }
  },
  {
    serializeError: serializeError,
  },
);

export const evaluationUseCasesThunks = {
  fetchEvaluationUseCases,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "evaluationUseCases",
  initialState,
  reducers: {
    setUseCases(state, action) {
      const { data, pagination } = action.payload;
      // state.useCases.entities = {};
      state.useCases.demoUseCasesIds = [];
      state.useCases.liveUseCasesIds = [];
      state.useCases.archivedIds = [];
      state.useCases.archived = [];
      if (notEmptyArray(data)) {
        data.forEach(useCase => {
          if (useCase.active === true) {
            state.useCases.entities[useCase.id] = useCase;
            if (useCase.demo) {
              state.useCases.demoUseCasesIds.push(useCase.id);
            } else {
              state.useCases.liveUseCasesIds.push(useCase.id);
            }
          } else {
            state.useCases.archivedIds.push(useCase.id);
            state.useCases.archived[useCase.id] = useCase;
          }
        });
        state.dashboard.pagination.page = pagination.current_page;
        state.dashboard.pagination.totalPages = pagination.total_pages;
        state.dashboard.pagination.totalAPIs = pagination.total_entries;
      }
    },
    setUseCasesDashboardLoading(state) {
      state.dashboard.fetchStatus = "PENDING";
      state.dashboard.error = null;
    },
    // currently, we only set page via params, so for now params === pagination.
    // In the future, this action will be used to set values of all query string params
    setUseCasesDashboardSearchParams(state, action) {
      const { page, sort, direction } = action.payload;
      state.dashboard.pagination.page = page;
      state.dashboard.pagination.sort = sort;
      state.dashboard.pagination.direction = direction;
    },
    setUseCaseDefaultChannelQueriesData(state, action) {
      const { useCaseId, data, providersLogos } = action.payload;
      const useCase = state.useCases.entities[useCaseId];
      if (useCase) {
        useCase.defaultChannelQueriesData = data;
        useCase.defaultChannelQueriesDataCacheTimestamp = new Date().getTime();
        if (providersLogos) {
          useCase.defaultChannelQueriesDataProvidersLogos = providersLogos;
        }
      }
    },
    setDateRange(state, action) {
      const { startDate, endDate } = action.payload;
      try {
        state.startDate = startDate;
        state.endDate = endDate;
      } catch {
        state.startDate = initialState.startDate;
        state.endDate = initialState.endDate;
      }
    },
  },
  extraReducers: {
    [fetchEvaluationUseCases.fulfilled]: state => {
      state.dashboard.fetchStatus = "SUCCESS";
      state.dashboard.error = null;
    },
    [fetchEvaluationUseCases.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        return;
      }
      state.dashboard.fetchStatus = "FAILED";
      state.dashboard.error = action.error;
    },
    [userThunks.logoutUser.fulfilled]: () => {
      return initialState;
    },
  },
});

export const evaluationUseCasesActions = {
  ...actions,
};

export default reducer;
