const sitemap = [
  {
    id: "HOME",
    path: "/",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: "2022-03-21", priority: 1.0 },
  },
  {
    id: "ABOUT",
    path: "/about-us",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-03-10", priority: 1.0 },
  },
  {
    id: "PLATFORM_ROOT",
    path: "/app",
    public: true,
    app: "platform",
    type: "static",
    meta: { changefreq: "daily", lastmod: null, priority: 0.9 },
    internal_notes: "redirects to `ALL_USE_CASES`.",
  },
  {
    id: "TERMS_OF_SERVICE",
    path: "/app-terms-of-service",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2021-10-27", priority: 0.1 },
  },
  {
    id: "DATA_UTILIZATION",
    path: "/app/data-utilization",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "TABLE_PROVIDER_EXPORTS_INDEX",
    path: "/app/data_shares",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "TABLE_PROVIDER_EXPORTS_NEW",
    path: "/app/data_shares/new",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "TABLE_PROVIDER_IMPORTS_INDEX",
    path: "/app/table_provider_imports",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "TABLE_PROVIDER_IMPORTS_NEW",
    path: "/app/table_provider_imports/new",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "PLATFORM_CONTACT_US",
    path: "/app/contact",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "DATA_SHARE",
    path: "/app/data-share",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SEARCH_DOCUMENTATION",
    path: "/app/search-documentation",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "CREATE_DATA_API",
    path: "/app/create-api",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "RECIPE_ROOT",
    path: "/app/recipe",
    public: true,
    app: "platform",
    type: "redirect",
    internal_notes: "redirects to `DATA_CATALOG_RECIPES`.",
  },
  {
    id: "RECIPE_PAGE",
    path: "/app/recipe/:templateId",
    public: true,
    app: "platform",
    type: "catalog_recipe",
  },
  {
    id: "RECIPE_PAGE_WITH_NAME",
    path: "/app/recipe/:templateId/:sourceName",
    public: true,
    app: "platform",
    type: "catalog_recipe_with_name",
  },
  {
    id: "ARCHIVED_API_TEMPLATE_CATALOG",
    path: "/app/api-catalog",
    public: true,
    app: "platform",
    type: "redirect",
    internal_notes: "redirects to `DATA_CATALOG_RECIPES`.",
  },
  {
    id: "ARCHIVED_API_TEMPLATE_PAGE",
    path: "/app/api-catalog/:templateId",
    public: true,
    app: "platform",
    type: "redirect",
    internal_notes: "redirects to `RECIPE_PAGE`",
  },
  {
    id: "CONNECTOR_ROOT",
    path: "/app/connector",
    public: true,
    app: "platform",
    type: "redirect",
    internal_notes: "redirects to `DATA_CATALOG_CONNECTORS`.",
  },
  {
    id: "CONNECTOR_PAGE",
    path: "/app/connector/:productId",
    public: true,
    app: "platform",
    type: "catalog_connector",
  },
  {
    id: "ARCHIVED_PRODUCTS_CATALOG",
    path: "/app/catalog",
    public: true,
    app: "platform",
    type: "redirect",
    internal_notes: "redirects to `DATA_CATALOG_CONNECTORS`.",
  },
  {
    id: "ARCHIVED_PRODUCT_PAGE",
    path: "/app/catalog/:productId",
    public: true,
    app: "platform",
    type: "redirect",
    internal_notes: "redirects to `CONNECTOR_PAGE`",
  },
  {
    id: "CONNECTOR_PAGE_WITH_SOURCE",
    path: "/app/connector/:productId/:productSource",
    public: true,
    app: "platform",
    type: "catalog_connector_with_source",
  },
  {
    id: "CONNECTOR_PAGE_WITH_SOURCE_AND_NAME",
    path: "/app/connector/:productId/:productSource/:productName",
    public: true,
    app: "platform",
    type: "catalog_connector_with_source_and_name",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.9 },
  },
  {
    id: "DATA_CATALOG",
    path: "/app/data-catalog/connectors",
    public: true,
    app: "platform",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: null },
    internal_notes: "redirects to `DATA_CATALOG_HOME`",
  },
  {
    id: "DATA_CATALOG_CONNECTORS",
    path: "/app/data-catalog/connectors",
    public: true,
    app: "platform",
    type: "static",
    meta: { changefreq: "daily", lastmod: null, priority: 0.9 },
  },
  {
    id: "DATA_CATALOG_HOME",
    path: "/app/data-catalog/connectors",
    public: true,
    app: "platform",
    type: "static",
    meta: { changefreq: "daily", lastmod: null, priority: 0.9 },
  },
  {
    id: "DATA_CATALOG_RECIPES",
    path: "/app/data-catalog/recipes",
    public: true,
    app: "platform",
    type: "static",
    meta: { changefreq: "daily", lastmod: null, priority: 0.9 },
  },

  // For later ...
  // {
  //   id: "DATA_CATALOG_SOURCE_ROOT",
  //   path: "/app/data-catalog/source",
  //   public: true,
  //   app: "platform",
  //   type: "redirect",
  //   internal_notes: "redirects to `DATA_CATALOG_CONNECTORS`.",
  // },
  // {
  //   id: "DATA_CATALOG_SOURCE",
  //   path: "/app/data-catalog/source/:sourceId",
  //   public: true,
  //   app: "platform",
  //   type: "redirect",
  //   internal_notes: "redirects to `DATA_CATALOG_CONNECTORS`.",
  // },
  {
    id: "FILE_TRANSFERS",
    path: "/app/file_transfers",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "PLATFORM_HOME",
    path: "/app/home",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USER_ACTIVITY",
    path: "/app/user-activity",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "INTERNAL_DATA_CONNECTORS",
    path: "/app/internal-data/connectors",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "REPORTS_MONITOR",
    path: "/app/reports-monitor",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "PROJECTS_ALL",
    path: "/app/projects/all",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "PROJECTS_SANDBOX",
    path: "/app/projects/sandbox",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "PROJECTS_DEPLOYED",
    path: "/app/projects/deployed",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "PROJECT_SUMMARY",
    path: "/app/projects/:id/summary/:slug",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "PROJECT_SETTINGS",
    path: "/app/projects/:id/settings/:slug",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "KNOWLEDGE_CENTER",
    path: "/app/knowledge-center",
    public: false,
    app: "platform",
    type: "static",
    internal_notes: "`show: false` in platformRoutes.js",
  },
  {
    id: "KNOWLEDGE_CENTER_ARTICLE",
    path: "/app/knowledge-center/:category/:slug",
    public: false,
    app: "platform",
    type: "static",
    internal_notes: "`show: false` in platformRoutes.js",
  },
  {
    id: "KNOWLEDGE_CENTER_CATEGORY",
    path: "/app/knowledge-center/category/:category",
    public: false,
    app: "platform",
    type: "static",
    internal_notes: "`show: false` in platformRoutes.js",
  },
  {
    id: "SETTINGS_PROFILE",
    path: "/app/profile",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SETTINGS",
    path: "/app/settings",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SETTINGS_GENERAL",
    path: "/app/settings/general",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SETTINGS_USERS",
    path: "/app/settings/users",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SETTINGS_USER_ACTIVITY",
    path: "/app/settings/user-activity",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SETTINGS_DATA_UTILIZATION",
    path: "/app/settings/data-utilization",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SETTINGS_BILLING",
    path: "/app/settings/billing",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SEARCH_RESULTS",
    path: "/app/search-results",
    public: false,
    app: "platform",
    type: "static",
    internal_notes: "redirects to `ALL_SEARCH_RESULTS`",
  },
  {
    id: "ALL_SEARCH_RESULTS",
    path: "/app/search-results/all",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "API_SEARCH_RESULTS",
    path: "/app/search-results/apis",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "CONNECTORS_SEARCH_RESULTS",
    path: "/app/search-results/connectors",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "RECIPES_SEARCH_RESULTS",
    path: "/app/search-results/recipes",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SOURCES_SEARCH_RESULTS",
    path: "/app/search-results/sources",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "CATEGORIES_SEARCH_RESULTS",
    path: "/app/search-results/categories",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "ATTRIBUTES_SEARCH_RESULTS",
    path: "/app/search-results/attributes",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "DOCUMENTATION_SEARCH_RESULTS",
    path: "/app/search-results/documentation",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "SOURCES",
    path: "/app/sources",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "TAGS",
    path: "/app/tags",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "CREATE_SCHEMA",
    path: "/app/create-own-providers",
    public: false,
    app: "platform",
    type: "static",
    internal_notes: "",
  },
  {
    id: "TOS_ACCEPTANCE",
    path: "/app/tos-acceptance",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USE_CASES",
    path: "/app/use-cases-dashboard",
    public: false,
    app: "platform",
    type: "static",
    internal_notes: "redirects to `ALL_USE_CASES`",
  },
  {
    id: "USE_CASE_PAGE_ROOT",
    path: "/app/use-cases",
    public: false,
    app: "platform",
    type: "redirect",
  },
  {
    id: "USE_CASE_PAGE",
    path: "/app/use-cases/:useCaseId",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USE_CASE_OVERVIEW",
    path: "/app/use-cases/:useCaseId/overview",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USE_CASE_ERRORS",
    path: "/app/use-cases/:useCaseId/errors",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USE_CASE_REPORTS",
    path: "/app/use-cases/:useCaseId/reports",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USE_CASE_SETTINGS",
    path: "/app/use-cases/:useCaseId/settings",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USE_CASE_TESTS",
    path: "/app/use-cases/:useCaseId/tests",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USE_CASE_BATCHES",
    path: "/app/use-cases/:useCaseId/batches",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "USE_CASE_ALERTS",
    path: "/app/use-cases/:useCaseId/alerts",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "ALL_USE_CASES",
    path: "/app/use-cases-dashboard/all",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "PRODUCTION_USE_CASES",
    path: "/app/use-cases-dashboard/production",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "EVALUATION_USE_CASES",
    path: "/app/use-cases-dashboard/evaluation",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "CREATED_USE_CASES",
    path: "/app/use-cases-dashboard/created",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "ARCHIVED_USE_CASES",
    path: "/app/use-cases-dashboard/archived",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "RECIPES_USE_CASES",
    path: "/app/use-cases-dashboard/recipes",
    public: true,
    app: "platform",
    type: "static",
    meta: { changefreq: "daily", lastmod: null, priority: 0.9 },
  },
  {
    id: "AWS_CASE_STUDY",
    path: "/aws-case-study/:id",
    public: true,
    app: "marketing",
    type: "aws-case-study",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.6 },
  },
  {
    id: "RESOURCES",
    path: "/resources",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.6 },
    internal_notes: "",
  },
  {
    id: "ADX_DEMYST_EXCHANGE",
    path: "/aws-data-exchange/demyst-partnership",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-01-01", priority: 0.4 },
  },
  {
    id: "BLOGMAIN",
    path: "/blog",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "BLOGPOST",
    path: "/blog/:id",
    public: true,
    app: "marketing",
    type: "blog_post",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.5 },
  },
  {
    id: "USECASE_BNPL",
    path: "/bnpl",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2021-10-01", priority: 0.5 },
  },
  {
    id: "CAREERS",
    path: "/careers",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "daily", lastmod: null, priority: 0.7 },
  },
  {
    id: "CHANGE_PASSWORD",
    path: "/change-password",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.1 },
  },
  {
    id: "CONFIRM",
    path: "/confirm",
    public: false,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.1 },
  },
  {
    id: "CONSOLE_DOCUMENTATION",
    path: "/console/documentation",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_API_REFERENCE",
    path: "/console/documentation/api_reference",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_AUTHENTICATION",
    path: "/console/documentation/authentication",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_INTEGRATION",
    path: "/console/documentation/integration",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_DEPLOYMENT",
    path: "/console/documentation/deployment",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_GETTING_STARTED",
    path: "/console/documentation/getting_started",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB",
    path: "/console/documentation/knowledge_base",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_ARCHIVED",
    path: "/console/documentation/knowledge_base/archived_lookups",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_BATCH",
    path: "/console/documentation/knowledge_base/config_api_batch_run",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_FT",
    path: "/console/documentation/knowledge_base/demyst_file_transfer",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_SERVICES",
    path: "/console/documentation/knowledge_base/demyst_services",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_PRICING",
    path: "/console/documentation/knowledge_base/pricing",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_SLAS",
    path: "/console/documentation/knowledge_base/service_level_agreements",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_SERVICE_CREDITS",
    path: "/console/documentation/knowledge_base/service_level_credits",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_SERVICE_METRICS",
    path: "/console/documentation/knowledge_base/service_level_metrics_definition",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_SEVERITY",
    path: "/console/documentation/knowledge_base/severity_levels_definition",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONSOLE_DOCUMENTATION_KB_USER",
    path: "/console/documentation/knowledge_base/user_management",
    public: true,
    app: "manta",
    type: "routed_from_manta",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.6 },
  },
  {
    id: "CONTACT",
    path: "/contact",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-01-01", priority: 0.9 },
  },
  {
    id: "REQUEST_DEMO",
    path: "/request-demo",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-07-12", priority: 1.0 },
  },
  {
    id: "COOKIE_POLICY",
    path: "/cookie-policy",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-03-01", priority: 0.1 },
  },
  {
    id: "SUBPROCESSOR_POLICY",
    path: "/subprocessor-policy",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-06-15", priority: 0.1 },
  },
  {
    id: "DATAAPI_SIMPLE_DEPLOYMENT",
    path: "/data-api/simple-deployment",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "DATAAPI_NO_CODE_DEPLOYMENT",
    path: "/data-api/no-code-deployment",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "DATAAPI_RESTFUL_DEPLOYMENT",
    path: "/data-api/restful-deployment",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "DATAAPI_FRICTIONLESS_DEPLOYMENT",
    path: "/data-api/frictionless-deployment",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "DATAAPI_DEPLOYMENT_MANAGEMENT",
    path: "/data-api/deployment-management",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "DATAAPI_LIFECYCLE_MANAGEMENT",
    path: "/data-api/lifecycle-management",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "DATAAPI_CENTRALIZE_MANAGEMENT",
    path: "/data-api/centralize-management",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "DSAR",
    path: "/data-subject-action-request",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2021-01-01", priority: 0.1 },
  },

  {
    id: "ESGREPORT",
    path: "/esg-report",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "REPORT2022",
    path: "/external-data-predictions-report-2022",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2021-01-01", priority: 0.5 },
  },
  {
    id: "FORGOT_PASSWORD",
    path: "/forgot-password",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.1 },
  },
  {
    id: "GENERATED_USE_CASE_DATA_SOURCE",
    path: "/external-data/:tagCategory/:tagName/:dataSource",
    public: true,
    app: "marketing",
    type: "generated-tag-data-source",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "THIRD_GENERATED_USE_CASE_DATA_SOURCE",
    path: "/third-party-data/:tagCategory/:tagName/:dataSource",
    public: true,
    app: "marketing",
    type: "redirect",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  // {
  //   id: "GENERATED_USE_CASE_DATA_CONNECTOR",
  //   path: "/external-data/:tagCategory/:tagName/connector/:connectorName",
  //   public: true,
  //   app: "marketing",
  //   type: "generated-tag-data-connector",
  //   meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  //
  // },
  // {
  //   id: "THIRD_GENERATED_USE_CASE_DATA_CONNECTOR",
  //   path: "/third-external-data/:tagCategory/:tagName/connector/:connectorName",
  //   public: true,
  //   app: "marketing",
  //   type: "generated-tag-data-connector",
  //   meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  //
  // },
  {
    id: "GENERATED_USE_CASE",
    path: "/external-data/:tagCategory/:tagName",
    public: true,
    app: "marketing",
    type: "generated-tag",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "THIRD_GENERATED_USE_CASE",
    path: "/third-party-data/:tagCategory/:tagName",
    public: true,
    app: "marketing",
    type: "redirect",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "GENERATED_USE_CASE_REGION",
    path: "/external-data/:tagCategory/:tagName/region/:regionName",
    public: true,
    app: "marketing",
    type: "generated-tag-region",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "THIRD_GENERATED_USE_CASE_REGION",
    path: "/third-party-data/:tagCategory/:tagName/region/:regionName",
    public: true,
    app: "marketing",
    type: "redirect",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "GENERATED_DATA_SOURCE",
    path: "/external-data/source/:dataSource",
    public: true,
    app: "marketing",
    type: "generated-data-source",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "THIRD_GENERATED_DATA_SOURCE",
    path: "/third-party-data/source/:dataSource",
    public: true,
    app: "marketing",
    type: "redirect",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "GENERATED_DATA_SOURCE_REGION",
    path: "/external-data/source/:dataSource/region/:regionName/",
    public: true,
    app: "marketing",
    type: "generated-data-source-region",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "THIRD_GENERATED_DATA_SOURCE_REGION",
    path: "/third-party-data/source/:dataSource/region/:regionName/",
    public: true,
    app: "marketing",
    type: "redirect",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.5 },
  },
  {
    id: "INDUSTRIES_FINANCIAL_SERVICES",
    path: "/industries/financial-services",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-06-15", priority: 0.5 },
    internal_notes: "",
  },
  {
    id: "INDUSTRIES_INSURANCE",
    path: "/industries/insurance",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-06-22", priority: 0.5 },
    internal_notes: "",
  },
  {
    id: "ENTERPRISE",
    path: "/enterprise",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-08-01", priority: 0.5 },
    internal_notes: "",
  },
  {
    id: "LANDING_TEMPLATE",
    path: "/info/:slug",
    public: true,
    app: "marketing",
    type: "landing-template",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.2 },
    internal_notes: "used for buttercms page templates",
  },
  {
    id: "ACCEPT_INVITATION",
    path: "/invitation-accept",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_THIRD_PARTY_DATA",
    path: "/lp/third-party-data",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_EXTERNAL_DATA",
    path: "/lp/external-data",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_PEOPLE_DATA_API",
    path: "/lp/people-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_BUSINESS_DATA_API",
    path: "/lp/business-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_DIGITAL_DATA_API",
    path: "/lp/digital-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_PROPERTY_DATA_API",
    path: "/lp/property-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_AUTOMOTIVE_DATA_API",
    path: "/lp/automotive-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_CREDIT_DATA_API",
    path: "/lp/credit-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_KYB_DATA_API",
    path: "/lp/kyb-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_KYC_DATA_API",
    path: "/lp/kyc-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_LEADS_DATA_API",
    path: "/lp/leads-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LP_PREFILL_DATA_API",
    path: "/lp/prefill-data-api",
    public: true,
    app: "marketing",
    type: "landing",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "LOGIN",
    path: "/login",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 1.0 },
  },
  {
    id: "SWITCH_ORGANIZATON",
    path: "/switch-organization",
    public: false,
    app: "platform",
    type: "static",
  },
  {
    id: "MANAGE_COOKIE",
    path: "/manage-cookies",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-03-22", priority: 0.1 },
  },
  {
    id: "NEWSLETTER",
    path: "/newsletter",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-01-01", priority: 0.1 },
  },
  // {
  //   id: "PARTNERS",
  //   path: "/partners",
  //   public: true,
  //   app: "marketing",
  //   type: "static",
  //   meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 1.0 },
  // },
  {
    id: "CCPA",
    path: "/personal-information-request-california",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.1 },
  },
  {
    id: "PLATFORM",
    path: "/platform",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-01-01", priority: 1.0 },
  },
  // {
  //   id: "PLATFORM_DEPLOYMENT",
  //   path: "/platform/deployment",
  //   public: true,
  //   app: "marketing",
  //   type: "static",
  //   meta: { changefreq: "monthly", lastmod: "2022-07-01", priority: 1.0 },
  // },
  // {
  //   id: "PLATFORM_DISCOVER",
  //   path: "/platform/discover-the-right-data",
  //   public: true,
  //   app: "marketing",
  //   type: "static",
  //   meta: { changefreq: "monthly", lastmod: "2022-07-01", priority: 1.0 },
  // },
  // {
  //   id: "PLATFORM_MANAGEMENT",
  //   path: "/platform/external-data-management",
  //   public: true,
  //   app: "marketing",
  //   type: "static",
  //   meta: { changefreq: "monthly", lastmod: "2022-07-01", priority: 1.0 },
  // },
  // {
  //   id: "PLATFORM_GOVERNANCE",
  //   path: "/platform/governance-procurement-compliance",
  //   public: true,
  //   app: "marketing",
  //   type: "static",
  //   meta: { changefreq: "monthly", lastmod: "2022-07-01", priority: 1.0 },
  // },
  //  {
  //    id: "PRICING",
  //    path: "/pricing",
  //    public: true,
  //    app: "marketing",
  //    type: "static",
  //    meta: { changefreq: "monthly", lastmod: "2022-03-01", priority: 1.0 },
  //  },
  {
    id: "PRIVACY",
    path: "/privacy-policy",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-03-16", priority: 0.1 },
  },
  {
    id: "ACCESSIBILITY",
    path: "/accessibility-policy",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-03-16", priority: 0.1 },
  },
  {
    id: "REPORT",
    path: "/report",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-01-01", priority: 0.3 },
  },
  {
    id: "RESET_PASSWORD",
    path: "/reset-password",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.1 },
  },
  {
    id: "SIGN_UP",
    path: "/sign-up",
    public: true,
    app: "platform",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-03-21", priority: 1.0 },
  },
  {
    id: "TERMS",
    path: "/terms-and-conditions",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2022-03-16", priority: 0.1 },
  },
  {
    id: "UNLOCK_ACCOUNT",
    path: "/unlock-account",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.1 },
  },
  {
    id: "USECASE_PREFILL",
    path: "/use-cases/application-prefill",
    public: true,
    app: "marketing",
    type: "deprecated",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "USECASE_BUSINESS_ONBOARDING",
    path: "/use-cases/business-decisioning",
    public: true,
    app: "marketing",
    type: "deprecated",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "USECASE_DATA_ENRICHMENT",
    path: "/use-cases/contact-data-enrichment",
    public: true,
    app: "marketing",
    type: "deprecated",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "USECASE_CREDIT_BUREAU",
    path: "/use-cases/credit-bureau-consolidation",
    public: true,
    app: "marketing",
    type: "deprecated",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "USECASE_KYB",
    path: "/use-cases/kyb-workflow-automation",
    public: true,
    app: "marketing",
    type: "deprecated",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "USECASE_KYC",
    path: "/use-cases/kyc-workflow-automation",
    public: true,
    app: "marketing",
    type: "deprecated",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "USECASE_LEAD_TARGETING",
    path: "/use-cases/lead-targeting",
    public: true,
    app: "marketing",
    type: "deprecated",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "USECASE_PORTFOLIO_MONITORING",
    path: "/use-cases/portfolio-monitoring",
    public: true,
    app: "marketing",
    type: "deprecated",
    meta: { changefreq: "monthly", lastmod: "2022-02-01", priority: 0.5 },
  },
  {
    id: "WEBINAR_REGISTRATION",
    path: "/webinar/:id",
    public: true,
    app: "marketing",
    type: "webinar-registration",
    meta: { changefreq: "weekly", lastmod: null, priority: 0.1 },
  },
  {
    id: "WEBINARS",
    path: "/webinars",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "daily", lastmod: null, priority: 0.2 },
  },
  {
    id: "WEBINARS_VIDEO",
    path: "/webinars/video",
    public: true,
    app: "marketing",
    type: "redirect",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.2 },
  },
  {
    id: "WEBINARS_VIDEO_SLUGIFIED",
    path: "/webinars/watch/:id",
    public: true,
    app: "marketing",
    type: "webinars",
    meta: { changefreq: "monthly", lastmod: null, priority: 0.2 },
  },
  {
    id: "POWERING",
    path: "/powering",
    public: true,
    app: "marketing",
    type: "static",
    meta: { changefreq: "monthly", lastmod: "2024-04-04", priority: 1.0 },
  },
];

module.exports = {
  sitemap,
};
