import { demystConsoleApi } from "./api";

export const internalConnectorsApi = {
  async createConnector({
    name,
    vendor_name,
    description,
    created_by,
    diligence_status,
    commercial_status,
    integration_status,
  }) {
    return await demystConsoleApi.post(
      `/internal_connectors`,
      {
        connector: {
          name,
          description,
          created_by,
          vendor_name,
          diligence_status,
          commercial_status,
          integration_status,
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async getConnectors() {
    return await demystConsoleApi.get(`/internal_connectors`, {
      withCredentials: true,
    });
  },

  async updateConnectors(
    id,
    {
      name,
      vendor_name,
      description,
      created_by,
      diligence_status,
      commercial_status,
      integration_status,
      region,
      entity,
      website,
      vendor_description,
      use_case,
      description_of_use,
      evaluation_volume,
      production_volume,
      production_start_date,
      diligence_internal_reference,
      diligence_status_notes,
      diligence_category,
      diligence_notes,
      commercial_internal_reference,
      commercial_status_notes,
      commercial_notes,
      integration_internal_reference,
      integration_status_notes,
      integration_notes,
      free_paid_evaluations,
      volume_limits,
      time_limits,
      deal_registration_process,
      pricing,
      tiered_pricing,
      permitted_uses,
      data_use_restrictions,
      do_not_sell_list,
    },
  ) {
    return await demystConsoleApi.put(
      `/internal_connectors/${id}`,
      {
        connector: {
          name,
          description,
          created_by,
          vendor_name,
          diligence_status,
          commercial_status,
          integration_status,
          region,
          entity,
          website,
          vendor_description,
          use_case,
          description_of_use,
          evaluation_volume,
          production_volume,
          production_start_date,
          diligence_internal_reference,
          diligence_status_notes,
          diligence_category,
          diligence_notes,
          commercial_internal_reference,
          commercial_status_notes,
          commercial_notes,
          integration_internal_reference,
          integration_status_notes,
          integration_notes,
          free_paid_evaluations,
          volume_limits,
          time_limits,
          deal_registration_process,
          pricing,
          tiered_pricing,
          permitted_uses,
          data_use_restrictions,
          do_not_sell_list,
        },
      },
      {
        withCredentials: true,
      },
    );
  },
};
