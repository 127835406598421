const prepareCell = cell => {
  if (!cell) {
    return "";
  }

  return `"${cell}"`;
};

export const createCSVBlob = string => {
  if (!string) {
    return null;
  }

  return new Blob([string], {
    type: "text/csv",
  });
};

export const getBlobUrl = blob => {
  if (!blob) {
    return null;
  }

  return URL.createObjectURL(blob);
};

/**
 * Supported data shapes:
 * 1. Array of objects like:
 * {
 *   label1: value1,
 *   label2: value2,
 *   ...
 * }
 * Each object is a row, labels become column's names, values become corresponding column's values
 * @param data {[{}]}
 */
export const createCSV = data => {
  if (!data || !Array.isArray(data) || !data?.length) {
    return null;
  }
  const headers = Object.keys(data[0]);
  const rows = data.slice(1).map(row => headers.map(header => row[header]));
  const CSVData = [headers, ...rows];
  return CSVData.map(row => row.map(prepareCell).join(",")).join("\r\n");
};

/**
 * Supported data shapes:
 * 1. Array of arrays where first item is array of names of columns and the rest are arrays with
 * values for respective columns
 * [
 *   ["label1", "label2", "label3"],
 *   [value1, value2, value3],
 *   [value1, value2, value3],
 * ]
 * @param csv
 * @constructor
 */
export const CSVtoJSON = csv => {
  const tRawData = [...csv];
  const labelsArray = tRawData[0];
  return tRawData.splice(1).map(d => {
    return Object.assign(
      {},
      ...labelsArray.map((label, index) => {
        return { [label]: d[index] };
      }),
    );
  });
};
