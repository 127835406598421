import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { operationalDashboardApi } from "../../api/operationalDashboardApi";
import { userThunks } from "../user/userSlice";
import { serializeError } from "../../utils/serializeError";
import { format, sub } from "date-fns";
import Axios from "axios";
import { notEmptyArray } from "../../utils/helpers";

const initialState = {
  startDate: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd"),
  endDate: format(new Date(), "yyyy-MM-dd"),
  useCases: {
    entities: {},
    liveUseCasesIds: [],
    demoUseCasesIds: [],
  },
  dashboard: {
    fetchStatus: "IDLE",
    error: null,
    pagination: {
      totalPages: 10,
      totalAPIs: 10,
      use_cases_page: 1,
      sort: "",
      direction: "",
      search: "",
    },
    requestId: null,
  },
};

// --- THUNKS START ---
const fetchAllUseCases = createAsyncThunk(
  "allUseCases/fetchAllUseCases",
  async (payload, thunkAPI) => {
    const { allUseCases } = thunkAPI.getState();
    const source = Axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    // validate all params from qs (currently, it's only pagination related so for now
    // params === pagination);
    const queryStringParams = {
      page: payload?.use_cases_page || initialState.dashboard.pagination.use_cases_page,
      sort: payload?.sort || initialState.dashboard.pagination.sort,
      direction: payload?.direction || initialState.dashboard.pagination.direction,
      search: payload?.search || initialState.dashboard.pagination.search,
      startDate: allUseCases?.startDate,
      endDate: allUseCases?.endDate,
    };
    // Data should be fetched only when params are different from what's already in Redux
    // or if there are no use cases or we've just removed use case
    const shouldFetch =
      payload.forceFetch ||
      Object.entries(queryStringParams).some(entry => {
        return entry[1]?.toString() !== allUseCases.dashboard.pagination[entry[0]]?.toString();
      }) ||
      [...allUseCases.useCases.liveUseCasesIds, ...allUseCases.useCases.demoUseCasesIds].length ===
        0 ||
      allUseCases.dashboard.fetchStatus === "IDLE" ||
      allUseCases.dashboard.fetchStatus === "FAILED";
    if (shouldFetch) {
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(allUseCasesActions.setUseCasesDashboardLoading());
        thunkAPI.dispatch(allUseCasesActions.setUseCasesDashboardSearchParams(queryStringParams));
      }
      const { data, headers } = await operationalDashboardApi.getUseCases(queryStringParams, {
        cancelToken: source.token,
      });
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(
          allUseCasesActions.setUseCases({
            data,
            pagination: JSON.parse(headers["x-pagination"]),
          }),
        );
      }
    }
  },
  {
    serializeError: serializeError,
  },
);

export const allUseCasesThunks = {
  fetchAllUseCases,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "allUseCases",
  initialState,
  reducers: {
    setUseCases(state, action) {
      const { data, pagination } = action.payload;
      state.useCases.demoUseCasesIds = [];
      state.useCases.liveUseCasesIds = [];
      state.useCases.archivedIds = [];
      state.useCases.archived = [];
      if (notEmptyArray(data)) {
        data.forEach(useCase => {
          if (useCase.active === true) {
            state.useCases.entities[useCase.id] = useCase;
            if (useCase.demo) {
              state.useCases.demoUseCasesIds.push(useCase.id);
            } else {
              state.useCases.liveUseCasesIds.push(useCase.id);
            }
          } else {
            state.useCases.archivedIds.push(useCase.id);
            state.useCases.archived[useCase.id] = useCase;
          }
        });
        // all use cases need to be sorted by `updated_at`
        const sortingFunction = (a, b) => {
          let dateA = new Date(state.useCases.entities[a].updated_at);
          let dateB = new Date(state.useCases.entities[b].updated_at);
          if (dateA < dateB) return 1;
          if (dateA > dateB) return -1;
          return 0;
        };
        state.useCases.demoUseCasesIds = state.useCases.demoUseCasesIds.sort(sortingFunction);
        // state.useCases.liveUseCasesIds = state.useCases.liveUseCasesIds.sort(sortingFunction);
        // set pagination params
        state.dashboard.pagination.page = pagination.current_page;
        state.dashboard.pagination.totalPages = pagination.total_pages;
        state.dashboard.pagination.totalAPIs = pagination.total_entries;
      }
    },
    setUseCasesDashboardLoading(state) {
      state.dashboard.fetchStatus = "PENDING";
      state.dashboard.error = null;
    },
    // currently, we only set page via params, so for now params === pagination.
    // In the future, this action will be used to set values of all query string params
    setUseCasesDashboardSearchParams(state, action) {
      const { page, sort, direction, search } = action.payload;
      state.dashboard.pagination.page = page;
      state.dashboard.pagination.sort = sort;
      state.dashboard.pagination.direction = direction;
      state.dashboard.pagination.search = search;
    },
    setUseCaseDefaultChannelQueriesData(state, action) {
      const { useCaseId, data, providersLogos } = action.payload;
      const useCase = state.useCases.entities[useCaseId];
      if (useCase) {
        useCase.defaultChannelQueriesData = data;
        useCase.defaultChannelQueriesDataCacheTimestamp = new Date().getTime();
        if (providersLogos) {
          useCase.defaultChannelQueriesDataProvidersLogos = providersLogos;
        }
      }
    },
    setDateRange(state, action) {
      const { startDate, endDate } = action.payload;
      try {
        state.startDate = startDate;
        state.endDate = endDate;
      } catch {
        state.startDate = initialState.startDate;
        state.endDate = initialState.endDate;
      }
    },
  },
  extraReducers: {
    [fetchAllUseCases.fulfilled]: state => {
      state.dashboard.fetchStatus = "SUCCESS";
      state.dashboard.error = null;
    },
    [fetchAllUseCases.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        return;
      }
      state.dashboard.fetchStatus = "FAILED";
      state.dashboard.error = action.error;
    },
    [userThunks.logoutUser.fulfilled]: () => {
      return initialState;
    },
  },
});

export const allUseCasesActions = {
  ...actions,
};

export default reducer;
