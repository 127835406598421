import { demystConsoleApi } from "./api";
import { format } from "date-fns";
import queryString from "query-string";

export const operationalDashboardApi = {
  async getProductionUseCases(params, configuration) {
    return await demystConsoleApi.get("/operational_dashboards?production=true", {
      withCredentials: true,
      params: { per_page: 12, page: params.page },
      ...configuration,
    });
  },

  async getEvaluationUseCases(params, configuration) {
    return await demystConsoleApi.get("/operational_dashboards?evaluation=true", {
      withCredentials: true,
      params: { per_page: 12, page: params.page, sort: params.sort, direction: params.direction },
      ...configuration,
    });
  },

  async getCreatedUseCases(params, configuration) {
    return await demystConsoleApi.get("/operational_dashboards?created_by_me=true", {
      withCredentials: true,
      params: { per_page: 12, page: params.page },
      ...configuration,
    });
  },

  async getUseCases(params, configuration) {
    return await demystConsoleApi.get("/operational_dashboards", {
      withCredentials: true,
      params: {
        per_page: 12,
        page: params.page,
        sort: params.sort,
        direction: params.direction,
        search: params.search,
        start_date: params.startDate,
        end_date: params.endDate,
      },
      ...configuration,
    });
  },

  async getUseCase(useCaseId, configuration) {
    return await demystConsoleApi.get(`/operational_dashboards/${useCaseId}.json`, {
      withCredentials: true,
      ...configuration,
    });
  },

  async updateUseCase(useCaseId, data) {
    return await demystConsoleApi.patch(`/operational_dashboards/${useCaseId}`, data, {
      withCredentials: true,
    });
  },

  async deleteUseCase(useCaseId) {
    return await demystConsoleApi.delete(`/operational_dashboards/${useCaseId}`, {
      withCredentials: true,
    });
  },

  async cloneUseCase(channelId) {
    return await demystConsoleApi.post(
      `/channels_clones`,
      {
        channel_id: channelId,
      },
      {
        withCredentials: true,
      },
    );
  },

  async changeUseCaseVisibility(useCaseId) {
    return await demystConsoleApi.post(
      `/operational_dashboards/change_visibility`,
      {
        ops_dash_use_case_id: useCaseId,
      },
      {
        withCredentials: true,
      },
    );
  },

  async getQueryData(
    { channelsIds, queryType, startDate, endDate, useCachedData = true, withProviders },
    configuration,
  ) {
    return await demystConsoleApi.get(
      `/operational_dashboards/${useCachedData ? "cache_" : ""}test.json`,
      {
        withCredentials: true,
        params: {
          channel_id: channelsIds,
          query_type: queryType,
          start_date: startDate,
          end_date: endDate,
          with_providers: withProviders,
        },
        paramsSerializer: p => {
          return queryString.stringify(p, { arrayFormat: "bracket" });
        },
        ...configuration,
      },
    );
  },

  async getUseCaseErrors({ useCaseId, startDate, endDate, channelsIds, errorType }, configuration) {
    return await demystConsoleApi.get(`/operational_dashboards/${useCaseId}/errors`, {
      params: {
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        channel_id: channelsIds,
        error_type: errorType,
      },
      paramsSerializer: p => {
        return queryString.stringify(p, { arrayFormat: "bracket" });
      },
      withCredentials: true,
      ...configuration,
    });
  },

  async getUseCaseErrorsCsv(
    { useCaseId, startDate, endDate, channelsIds, errorType },
    configuration,
  ) {
    return await demystConsoleApi.get(`/operational_dashboards/${useCaseId}/errors.csv`, {
      params: {
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        channel_id: channelsIds,
        error_type: errorType,
      },
      paramsSerializer: p => {
        return queryString.stringify(p, { arrayFormat: "bracket" });
      },
      withCredentials: true,
      ...configuration,
    });
  },

  async sendSupportEmail(data) {
    return await demystConsoleApi.post(
      "/support_requests",
      {
        support_request: data,
      },
      {
        withCredentials: true,
      },
    );
  },

  async updateChannel(channelId, channelData) {
    return await demystConsoleApi.put(
      `/channels/${channelId}`,
      {
        channel: channelData,
      },
      {
        withCredentials: true,
      },
    );
  },

  async getChannelNamesAndIds() {
    return await demystConsoleApi.get(`/channels?names_and_ids_only=true`, {
      withCredentials: true,
    });
  },

  async getBill({ start_date, end_date }, config) {
    return await demystConsoleApi.get(`/organization/billing/bills`, {
      params: { start_date, end_date },
      withCredentials: true,
      ...config,
    });
  },

  async getBillingUsage(
    { start_date, end_date, show_channel_id, show_table_provider_name },
    config,
  ) {
    return await demystConsoleApi.get(`/organization/billing/usage`, {
      params: { start_date, end_date, show_channel_id, show_table_provider_name },
      withCredentials: true,
      ...config,
    });
  },

  async getBillingCreditHistory({ start_date, end_date }, config) {
    return await demystConsoleApi.get(`/organization/billing/credits`, {
      params: { start_date, end_date },
      withCredentials: true,
      ...config,
    });
  },

  async getInvoice({ start_date, end_date }, config) {
    return await demystConsoleApi.get(`/organization/billing/contracts`, {
      params: { start_date, end_date },
      withCredentials: true,
      ...config,
    });
  },

  async optInToActivityOutOfBandNotification(useCaseId, standardDeviationsConsideredInBand) {
    return await demystConsoleApi.post(
      `/operational_dashboards/${useCaseId}/notifications`,
      {
        ops_dash_use_case_notification: {
          kind: "activity_out_of_band",
          options: { standard_deviations_considered_in_band: standardDeviationsConsideredInBand },
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async optOutOfActivityOutOfBandNotification(useCaseId, notificationId) {
    return await demystConsoleApi.delete(
      `/operational_dashboards/${useCaseId}/notifications/${notificationId}`,
      {
        withCredentials: true,
      },
    );
  },

  async updateActivityOutOfBandNotification(
    useCaseId,
    notificationId,
    standardDeviationsConsideredInBand,
  ) {
    return await demystConsoleApi.patch(
      `/operational_dashboards/${useCaseId}/notifications/${notificationId}`,
      {
        ops_dash_use_case_notification: {
          options: { standard_deviations_considered_in_band: standardDeviationsConsideredInBand },
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async optInToErrorRateNotification(useCaseId, percentage) {
    return await demystConsoleApi.post(
      `/operational_dashboards/${useCaseId}/notifications`,
      {
        ops_dash_use_case_notification: {
          kind: "error_rate_above_percentage",
          options: { percentage: percentage },
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async optOutOfErrorRateNotification(useCaseId, notificationId) {
    return await demystConsoleApi.delete(
      `/operational_dashboards/${useCaseId}/notifications/${notificationId}`,
      {
        withCredentials: true,
      },
    );
  },

  async updateErrorRateNotification(useCaseId, notificationId, percentage) {
    return await demystConsoleApi.patch(
      `/operational_dashboards/${useCaseId}/notifications/${notificationId}`,
      {
        ops_dash_use_case_notification: {
          options: { percentage: percentage },
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async optInToHitRateNotification(useCaseId, percentage) {
    return await demystConsoleApi.post(
      `/operational_dashboards/${useCaseId}/notifications`,
      {
        ops_dash_use_case_notification: {
          kind: "hit_rate_below_percentage",
          options: { percentage: percentage },
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async optOutOfHitRateNotification(useCaseId, notificationId) {
    return await demystConsoleApi.delete(
      `/operational_dashboards/${useCaseId}/notifications/${notificationId}`,
      {
        withCredentials: true,
      },
    );
  },

  async updateHitRateNotification(useCaseId, notificationId, percentage) {
    return await demystConsoleApi.patch(
      `/operational_dashboards/${useCaseId}/notifications/${notificationId}`,
      {
        ops_dash_use_case_notification: {
          options: { percentage: percentage },
        },
      },
      {
        withCredentials: true,
      },
    );
  },
};
