import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { serializeError } from "../../utils/serializeError";
import { internalConnectorsApi } from "../../api/internalConnectorsApi";

const initialState = {
  connectors: [],
  isConnectorsFetching: false,
  connectorsFetchingError: null,
};

// --- THUNKS START ---

const fetchConnectors = createAsyncThunk(
  "connectors/fetchConnectors",
  async (payload, thunkAPI) => {
    const { data } = await internalConnectorsApi.getConnectors();

    thunkAPI.dispatch(
      connectorsActions.setConnectors({
        connectors: data.connectors,
      }),
    );
  },
  {
    serializeError: serializeError,
  },
);

export const connectorsThunks = {
  fetchConnectors,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "connectors",
  initialState,
  reducers: {
    setConnectors(state, action) {
      const { connectors } = action.payload;
      state.connectors = connectors;
    },
  },
  extraReducers: {
    [fetchConnectors.pending]: state => {
      state.isConnectorsFetching = true;
    },
    [fetchConnectors.fulfilled]: state => {
      state.isConnectorsFetching = false;
    },
    [fetchConnectors.rejected]: (state, action) => {
      state.isConnectorsFetching = false;
      state.connectorsFetchingError = action.error || null;
    },
  },
});

export const connectorsActions = {
  ...actions,
};

export default reducer;
