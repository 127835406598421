import sortBy from "lodash.sortby";
import { addDays, compareAsc, compareDesc, format, isSameDay, sub } from "date-fns";
import { CSVtoJSON } from "../../utils/csv";
import { roundNumber } from "../../utils/helpers";
import { createDateFromString } from "../../utils/date";

export const useCaseQueryTypesConstants = {
  transactions: "transaction_counts",
  transactionsAndHitCountsByProvider: "transaction_and_hit_counts_by_provider",
  averageResponseTime: "average_response_time",
  averageResponseTimeByProvider: "average_response_time_by_provider",
  errors: "error_counts",
  errorsByProviders: "error_counts_by_provider",
};

export const reportsMetricsConstants = {
  transactions: "transactions",
  averageResponseTime: "averageResponseTime",
  errors: "errors",
};

export const dataProductsMetricsConstants = {
  matchRate: "matchRate",
  transactions: "transactions",
  averageResponseTime: "averageResponseTime",
  errors: "errors",
};

export const getSortedChannels = channels => {
  return sortBy(channels, "position");
};

export const getDefaultChannelId = channels => {
  return getSortedChannels(channels)[0]?.id;
};

export const calculateStartDate = minDate => {
  const monthAgo = sub(new Date(), { months: 1 });
  if (!minDate) {
    return monthAgo;
  }
  const minDateObj = new Date(minDate);
  return compareAsc(monthAgo, minDateObj) === 1 || isSameDay(monthAgo, minDateObj)
    ? monthAgo
    : minDateObj;
};

const generateDataInRange = (startDate, endDate, additionalData) => {
  const data = {};
  let iDate = createDateFromString(startDate);

  do {
    data[format(iDate, "MM-dd-yyyy")] = {
      date: format(iDate, "MM-dd-yyyy"),
      ...additionalData,
    };
    iDate = addDays(iDate, 1);
  } while (compareDesc(iDate, createDateFromString(endDate)) >= 0);
  return data;
};

const addDateField = item => {
  const { day, month, year } = item;
  if (day && month && year) {
    const fullDate = `${month}-${day}-${year}`;
    return {
      ...item,
      date: fullDate,
    };
  } else {
    return item;
  }
};

const calculateMatchRate = (transactionCount, matchCount) => {
  if (transactionCount) {
    return roundNumber((matchCount / transactionCount) * 100);
  } else {
    return 0;
  }
};

export const getAverageResponseTime = values => {
  const notZeroValues = values.filter(value => value > 0);
  const avg = notZeroValues.reduce((a, b) => a + b, 0) / (notZeroValues.length || 1);
  return roundNumber(avg);
};

// MASTER TRANSACTIONS

export const formatTransactionsData = ({
  transactionsRawData,
  activeChannelsIds,
  startDate,
  endDate,
}) => {
  try {
    let collection = buildObjectByDate(startDate, endDate, function () {
      return {
        channelsData: activeChannelsIds.reduce((memo, id) => {
          memo[id] = {
            channelId: id,
            sampleTransactionCount: 0,
            liveTransactionCount: 0,
            matchRate: 0,
            hitCount: 0,
          };
          return memo;
        }, {}),
      };
    });

    const records = csvToObjectsWithDate(transactionsRawData);

    records.forEach(record => {
      if (collection[record.date]) {
        let memo = collection[record.date].channelsData[record.channel_id];

        if (memo) {
          const transactionCount = Number(record.transaction_count);
          const hitCount = Number(record.hit_count);

          // anything non-sample is live
          const mode = record.mode === "sample" ? "sample" : "live";

          memo[`${mode}TransactionCount`] += transactionCount;
          memo.hitCount += hitCount;

          memo.matchRate = calculateMatchRate(
            memo.sampleTransactionCount + memo.liveTransactionCount,
            memo.hitCount,
          );
        }
      }
    });

    const transactionsPerChannelData = Object.values(collection).reduce(
      (memo, { /* date, */ channelsData }) => {
        Object.values(channelsData).forEach(entry => {
          memo[entry.channelId].totalTransactions +=
            entry.sampleTransactionCount + entry.liveTransactionCount;

          memo[entry.channelId].totalHitCount += entry.hitCount;
        });

        return memo;
      },
      Object.assign(
        {},
        ...Array.from(activeChannelsIds).map(id => {
          return {
            [id]: {
              channelId: id,
              totalTransactions: 0,
              totalHitCount: 0,
            },
          };
        }),
      ),
    );
    Object.values(transactionsPerChannelData).forEach(channel => {
      const n = Object.keys(collection).length;
      channel.transactionsPerDay = n === 0 ? 0 : channel.totalTransactions / n;
    });

    const transactionsMetadata = {
      domain: {
        min: 0,
        max: Math.max(
          ...Object.values(collection).map(({ /* date,*/ channelsData }) =>
            Object.values(channelsData)
              .map(channel => channel.sampleTransactionCount + channel.liveTransactionCount)
              .reduce((a, b) => a + b, 0),
          ),
        ),
      },
    };

    return {
      transactionsPerDayData: Object.values(collection),
      transactionsPerChannelData,
      transactionsMetadata,
    };
  } catch (e) {
    console.info(e);
  }
};

export const formatAverageResponseTimeData = ({
  averageResponseTimeRawData,
  activeChannelsIds,
  startDate,
  endDate,
}) => {
  try {
    const jsonedData = CSVtoJSON(averageResponseTimeRawData).map(item => {
      return addDateField(item);
    });

    const averageResponseTimeMetadata = {
      domain: {
        min: 0,
        max: 10,
      },
    };

    const averageResponseTimePerDayData = generateDataInRange(startDate, endDate, {
      channelsData: Object.assign(
        {},
        ...activeChannelsIds.map(channelId => {
          return {
            [channelId]: {
              channelId,
              averageResponseTime: 0,
              p99ResponseTime: 0,
              p95ResponseTime: 0,
            },
          };
        }),
      ),
    });

    const averageResponseTimePerChannelData = Object.assign(
      {},
      ...activeChannelsIds.map(channelId => {
        return {
          [channelId]: {
            channelId,
            totalAverageResponseTime: 0,
            averageResponseTimeCount: 0,
            totalP99ResponseTime: 0,
            p99ResponseTimeCount: 0,
            totalP95ResponseTime: 0,
            p95ResponseTimeCount: 0,
            averageResponseTime: 0,
            p99ResponseTime: 0,
            p95ResponseTime: 0,
          },
        };
      }),
    );

    jsonedData.forEach(d => {
      if (averageResponseTimePerDayData[d.date]) {
        averageResponseTimePerDayData[d.date] = {
          ...averageResponseTimePerDayData[d.date],
          channelsData: {
            ...averageResponseTimePerDayData[d.date].channelsData,
            [d.channel_id]: {
              ...averageResponseTimePerDayData[d.date].channelsData[d.channel_id],
              averageResponseTime: Number(d.average_response_time),
              p99ResponseTime: Number(d.p99_response_time),
              p95ResponseTime: Number(d.p95_response_time),
            },
          },
        };

        // Set metadata
        if (Number(d.average_response_time) > averageResponseTimeMetadata.domain.max) {
          averageResponseTimeMetadata.domain.max = Number(d.average_response_time);
        }
        if (Number(d.p99_response_time) > averageResponseTimeMetadata.domain.max) {
          averageResponseTimeMetadata.domain.max = Number(d.p99_response_time);
        }
        if (Number(d.p95_response_time) > averageResponseTimeMetadata.domain.max) {
          averageResponseTimeMetadata.domain.max = Number(d.p95_response_time);
        }

        averageResponseTimePerChannelData[d.channel_id].totalAverageResponseTime =
          averageResponseTimePerChannelData[d.channel_id].totalAverageResponseTime +
          Number(d.average_response_time);

        // When calculating avg response time for specific channel, we have to make sure that we
        // only count cases, where there actually was some response time
        if (Number(d.average_response_time) > 0) {
          averageResponseTimePerChannelData[d.channel_id].averageResponseTimeCount += 1;
        }

        averageResponseTimePerChannelData[d.channel_id].totalP99ResponseTime =
          averageResponseTimePerChannelData[d.channel_id].totalP99ResponseTime +
          Number(d.p99_response_time);

        if (Number(d.p99_response_time) > 0) {
          averageResponseTimePerChannelData[d.channel_id].p99ResponseTimeCount += 1;
        }

        averageResponseTimePerChannelData[d.channel_id].totalP95ResponseTime =
          averageResponseTimePerChannelData[d.channel_id].totalP95ResponseTime +
          Number(d.p95_response_time);

        if (Number(d.p95_response_time) > 0) {
          averageResponseTimePerChannelData[d.channel_id].p95ResponseTimeCount += 1;
        }
      }
    });

    // After all totals per channel are set, we have to iterate over all channels and
    // calculate average response time per response time type
    Object.values(averageResponseTimePerChannelData).forEach(e => {
      if (e.averageResponseTimeCount > 0) {
        e.averageResponseTime = e.totalAverageResponseTime / e.averageResponseTimeCount;
      }
      if (e.p99ResponseTimeCount > 0) {
        e.p99ResponseTime = e.totalP99ResponseTime / e.p99ResponseTimeCount;
      }
      if (e.p95ResponseTimeCount > 0) {
        e.p95ResponseTime = e.totalP95ResponseTime / e.p95ResponseTimeCount;
      }
    });

    return {
      averageResponseTimePerDayData: Object.values(averageResponseTimePerDayData),
      averageResponseTimePerChannelData,
      averageResponseTimeMetadata,
    };
  } catch (e) {
    console.info(e);
  }
};

export const formatErrorsData = ({ errorsRawData, activeChannelsIds, startDate, endDate }) => {
  try {
    const errorTypes = new Set();

    const jsonedData = CSVtoJSON(errorsRawData).map(item => {
      errorTypes.add(item.error_type);
      return addDateField(item);
    });

    const errorsMetadata = {
      domain: {
        min: 0,
        max: 10,
      },
    };

    const errorsPerDayData = generateDataInRange(startDate, endDate, {
      channelsData: Object.assign(
        {},
        ...activeChannelsIds.map(channelId => {
          return {
            [channelId]: {
              channelId,
              errorCount: 0,
            },
          };
        }),
      ),
    });

    const errorsPerChannelData = Object.assign(
      {},
      ...activeChannelsIds.map(channelId => {
        return {
          [channelId]: {
            channelId,
            errorCount: 0,
            errorsPerDay: 0,
          },
        };
      }),
    );

    jsonedData.forEach(d => {
      if (errorsPerDayData[d.date]) {
        const totalErrorsForChannelInDay =
          Number(errorsPerDayData[d.date].channelsData[d.channel_id].errorCount) +
          Number(d.error_count);
        errorsPerDayData[d.date] = {
          ...errorsPerDayData[d.date],
          channelsData: {
            ...errorsPerDayData[d.date].channelsData,
            [d.channel_id]: {
              ...errorsPerDayData[d.date].channelsData[d.channel_id],
              errorCount: totalErrorsForChannelInDay,
            },
          },
        };

        if (totalErrorsForChannelInDay > errorsMetadata.domain.max) {
          errorsMetadata.domain.max = totalErrorsForChannelInDay;
        }

        const totalErrors =
          Number(errorsPerChannelData[d.channel_id]?.errorCount) + Number(d.error_count);

        let errorsPerDay = 0;
        const nOfDays = Object.keys(errorsPerDayData).length;
        if (nOfDays > 0) {
          errorsPerDay = totalErrors / nOfDays;
        }

        if (Number(d.error_count))
          errorsPerChannelData[d.channel_id] = {
            ...errorsPerChannelData[d.channel_id],
            errorCount: totalErrors,
            errorsPerDay,
          };
      }
    });

    return {
      errorsPerDayData: Object.values(errorsPerDayData),
      errorsPerChannelData,
      errorsMetadata,
    };
  } catch (e) {
    console.info(e);
  }
};

// WITH PROVIDERS

export const formatTransactionsDataWithProviders = ({
  transactionsRawData,
  providers,
  startDate,
  endDate,
}) => {
  try {
    const records = csvToObjectsWithDate(transactionsRawData).filter(record => record.provider_id);

    const channelIds = Array.from(new Set(records.map(r => r.channel_id)));
    const providersNames = Object.keys(providers);

    let collection = buildObjectByDate(startDate, endDate, function () {
      return {
        channelsData: Object.assign(
          {},
          ...Array.from(channelIds).map(id => {
            return {
              [id]: Object.assign(
                {},
                ...providersNames.map(name => {
                  return {
                    [name]: {
                      name: name,
                      sampleTransactionCount: 0,
                      liveTransactionCount: 0,
                      matchRate: 0,
                      hitCount: 0,
                    },
                  };
                }),
              ),
            };
          }),
        ),
      };
    });

    records.forEach(record => {
      if (collection[record.date]) {
        let channelMemo = collection[record.date].channelsData[record.channel_id];

        if (channelMemo) {
          let providerMemo = channelMemo[record.provider_id];

          if (providerMemo) {
            const transactionCount = Number(record.transaction_count);
            const hitCount = Number(record.hit_count);

            // anything non-sample is live
            const mode = record.mode === "sample" ? "sample" : "live";

            providerMemo[`${mode}TransactionCount`] += transactionCount;
            providerMemo.hitCount += hitCount;

            providerMemo.matchRate = calculateMatchRate(
              providerMemo.sampleTransactionCount + providerMemo.liveTransactionCount,
              providerMemo.hitCount,
            );
          }
        }
      }
    });

    const transactionsPerProviderData = Object.values(collection).reduce(
      (memo, { /* date, */ channelsData }) => {
        Object.values(channelsData).forEach(channelEntry => {
          Object.values(channelEntry).forEach(providerEntry => {
            memo[providerEntry.name].totalTransactions +=
              providerEntry.sampleTransactionCount + providerEntry.liveTransactionCount;

            memo[providerEntry.name].totalHitCount += providerEntry.hitCount;
          });
        });

        return memo;
      },
      Object.assign(
        {},
        ...providersNames.map(provider => {
          return {
            [provider]: {
              name: provider,
              totalTransactions: 0,
              totalHitCount: 0,
            },
          };
        }),
      ),
    );
    Object.values(transactionsPerProviderData).forEach(provider => {
      const n = Object.keys(collection).length;
      provider.transactionsPerDay = n === 0 ? 0 : provider.totalTransactions / n;
    });

    const transactionsMetadata = {
      domain: {
        min: 0,
        max: Math.max(
          ...Object.values(collection)
            .map(({ /* date, */ channelsData }) => {
              return Object.values(channelsData).reduce((memo, providersData) => {
                Object.values(providersData).forEach(provider => {
                  if (!memo[provider.name]) {
                    memo[provider.name] = 0;
                  }

                  memo[provider.name] +=
                    provider.sampleTransactionCount + provider.liveTransactionCount;
                });

                return memo;
              }, {});
            })
            .map(Object.values)
            .flat(),
        ),
      },
    };

    return {
      transactionsPerDayData: Object.values(collection),
      transactionsPerProviderData,
      transactionsMetadata,
    };
  } catch (e) {
    console.info(e);
  }
};

export const formatAverageResponseTimeDataWithProviders = ({
  averageResponseTimeRawData,
  providers,
  startDate,
  endDate,
}) => {
  try {
    const channels = new Set();
    const providersNames = Object.keys(providers);

    const jsonedData = CSVtoJSON(averageResponseTimeRawData)
      .filter(item => item.provider_id)
      .map(item => {
        channels.add(item.channel_id);
        return addDateField(item);
      });

    const averageResponseTimeMetadata = {
      domain: {
        min: 0,
        max: 10,
      },
    };

    const averageResponseTimePerDayData = generateDataInRange(startDate, endDate, {
      channelsData: Object.assign(
        {},
        ...Array.from(channels).map(channel => {
          return {
            [channel]: Object.assign(
              {},
              ...providersNames.map(provider => {
                return {
                  [provider]: {
                    name: provider,
                    averageResponseTime: 0,
                    p99ResponseTime: 0,
                    p95ResponseTime: 0,
                  },
                };
              }),
            ),
          };
        }),
      ),
    });

    const averageResponseTimePerProviderData = Object.assign(
      {},
      ...providersNames.map(provider => {
        return {
          [provider]: {
            name: provider,
            totalAverageResponseTime: 0,
            averageResponseTimeCount: 0,
            totalP99ResponseTime: 0,
            p99ResponseTimeCount: 0,
            totalP95ResponseTime: 0,
            p95ResponseTimeCount: 0,
            averageResponseTime: 0,
            p99ResponseTime: 0,
            p95ResponseTime: 0,
          },
        };
      }),
    );

    jsonedData.forEach(d => {
      if (averageResponseTimePerDayData[d.date]) {
        averageResponseTimePerDayData[d.date] = {
          ...averageResponseTimePerDayData[d.date],
          channelsData: {
            ...averageResponseTimePerDayData[d.date].channelsData,
            [d.channel_id]: {
              ...averageResponseTimePerDayData[d.date].channelsData[d.channel_id],
              [d.provider_id]: {
                averageResponseTime: Number(d.average_response_time),
                p99ResponseTime: Number(d.p99_response_time),
                p95ResponseTime: Number(d.p95_response_time),
              },
            },
          },
        };

        // Set metadata
        if (Number(d.average_response_time) > averageResponseTimeMetadata.domain.max) {
          averageResponseTimeMetadata.domain.max = Number(d.average_response_time);
        }
        if (Number(d.p99_response_time) > averageResponseTimeMetadata.domain.max) {
          averageResponseTimeMetadata.domain.max = Number(d.p99_response_time);
        }
        if (Number(d.p95_response_time) > averageResponseTimeMetadata.domain.max) {
          averageResponseTimeMetadata.domain.max = Number(d.p95_response_time);
        }

        averageResponseTimePerProviderData[d.provider_id].totalAverageResponseTime =
          averageResponseTimePerProviderData[d.provider_id].totalAverageResponseTime +
          Number(d.average_response_time);

        // When calculating avg response time for specific provider, we have to make sure that we
        // only count cases, where there actually was some response time
        if (Number(d.average_response_time) > 0) {
          averageResponseTimePerProviderData[d.provider_id].averageResponseTimeCount += 1;
        }

        averageResponseTimePerProviderData[d.provider_id].totalP99ResponseTime =
          averageResponseTimePerProviderData[d.provider_id].totalP99ResponseTime +
          Number(d.p99_response_time);

        if (Number(d.p99_response_time) > 0) {
          averageResponseTimePerProviderData[d.provider_id].p99ResponseTimeCount += 1;
        }

        averageResponseTimePerProviderData[d.provider_id].totalP95ResponseTime =
          averageResponseTimePerProviderData[d.provider_id].totalP95ResponseTime +
          Number(d.p95_response_time);

        if (Number(d.p95_response_time) > 0) {
          averageResponseTimePerProviderData[d.provider_id].p95ResponseTimeCount += 1;
        }
      }
    });

    // After all totals per provider are set, we have to iterate over all providers and
    // calculate average response time per response time type
    Object.values(averageResponseTimePerProviderData).forEach(e => {
      if (e.averageResponseTimeCount > 0) {
        e.averageResponseTime = e.totalAverageResponseTime / e.averageResponseTimeCount;
      }
      if (e.p99ResponseTimeCount > 0) {
        e.p99ResponseTime = e.totalP99ResponseTime / e.p99ResponseTimeCount;
      }
      if (e.p95ResponseTimeCount > 0) {
        e.p95ResponseTime = e.totalP95ResponseTime / e.p95ResponseTimeCount;
      }
    });

    return {
      averageResponseTimePerDayData: Object.values(averageResponseTimePerDayData),
      averageResponseTimePerProviderData,
      averageResponseTimeMetadata,
    };
  } catch (e) {
    console.info(e);
  }
};

export const formatErrorsWithProviders = ({ errorsRawData, providers, startDate, endDate }) => {
  try {
    const channels = new Set();
    const errorTypes = new Set();
    const providersNames = Object.keys(providers);

    const jsonedData = CSVtoJSON(errorsRawData)
      .filter(item => item.provider_id)
      .map(item => {
        channels.add(item.channel_id);
        errorTypes.add(item.error_type);
        return addDateField(item);
      });

    const errorsMetadata = {
      domain: {
        min: 0,
        max: 10,
      },
    };

    const errorsPerDayData = generateDataInRange(startDate, endDate, {
      channelsData: Object.assign(
        {},
        ...Array.from(channels).map(channel => {
          return {
            [channel]: Object.assign(
              {},
              ...providersNames.map(provider => {
                return {
                  [provider]: {
                    name: provider,
                    errorCount: 0,
                  },
                };
              }),
            ),
          };
        }),
      ),
    });

    const errorsPerProviderData = Object.assign(
      {},
      ...providersNames.map(provider => {
        return {
          [provider]: {
            name: provider,
            errorCount: 0,
            errorsPerDay: 0,
          },
        };
      }),
    );

    jsonedData.forEach(d => {
      if (errorsPerDayData[d.date]) {
        const totalErrorsForProviderInDay =
          Number(errorsPerDayData[d.date].channelsData[d.channel_id][d.provider_id].errorCount) +
          Number(d.error_count);
        errorsPerDayData[d.date] = {
          ...errorsPerDayData[d.date],
          channelsData: {
            ...errorsPerDayData[d.date].channelsData,
            [d.channel_id]: {
              ...errorsPerDayData[d.date].channelsData[d.channel_id],
              [d.provider_id]: {
                errorCount: totalErrorsForProviderInDay,
              },
            },
          },
        };

        if (totalErrorsForProviderInDay > errorsMetadata.domain.max) {
          errorsMetadata.domain.max = totalErrorsForProviderInDay;
        }

        const totalErrors =
          Number(errorsPerProviderData[d.provider_id]?.errorCount) + Number(d.error_count);

        let errorsPerDay = 0;
        const nOfDays = Object.keys(errorsPerDayData).length;
        if (nOfDays > 0) {
          errorsPerDay = totalErrors / nOfDays;
        }

        if (Number(d.error_count))
          errorsPerProviderData[d.provider_id] = {
            ...errorsPerProviderData[d.provider_id],
            errorCount: totalErrors,
            errorsPerDay,
          };
      }
    });

    return {
      errorsPerDayData: Object.values(errorsPerDayData),
      errorsPerProviderData,
      errorsMetadata,
    };
  } catch (e) {
    console.info(e);
  }
};

// LEGACY (ONLY USED FOR DASHBOARD CURRENTLY, TO BE DELETED SOON)

function buildObjectByDate(startDate, endDate, initializer) {
  const data = {};
  let iDate = createDateFromString(startDate);

  do {
    data[format(iDate, "MM-dd-yyyy")] = {
      date: format(iDate, "MM-dd-yyyy"),
      ...(initializer ? initializer() : {}),
    };
    iDate = addDays(iDate, 1);
  } while (compareDesc(iDate, createDateFromString(endDate)) >= 0);

  return data;
}

function csvToObjectsWithDate(csv) {
  return CSVtoJSON(csv).map(record => addDateField(record));
}

export function formatTransactionsRawData({ rawData, startDate, endDate, channelsIds }) {
  let collection = buildObjectByDate(startDate, endDate, function () {
    return {
      channelsData: channelsIds.reduce((memo, id) => {
        memo[id] = { transactionCount: 0, matchCount: 0, matchRate: 0 };
        return memo;
      }, {}),
    };
  });

  const records = csvToObjectsWithDate(rawData);

  records.forEach(record => {
    if (collection[record.date]) {
      let memo = collection[record.date].channelsData[record.channel_id];

      if (memo) {
        const transactionCount = Number(record.transaction_count);
        const matchCount = Number(record.hit_count);

        memo.transactionCount += transactionCount;
        memo.matchCount += matchCount;

        memo.matchRate = calculateMatchRate(memo.transactionCount, memo.matchCount);
      }
    }
  });

  let meta = Object.values(collection).reduce(
    (memo, { /* date, */ channelsData }) => {
      const transactionsOnDate = Object.values(channelsData)
        .map(channel => channel.transactionCount)
        .reduce((a, b) => a + b, 0);

      const matchesOnDate = Object.values(channelsData)
        .map(channel => channel.matchCount)
        .reduce((a, b) => a + b, 0);

      memo.transactionsPerDay.push(transactionsOnDate);
      memo.matchesPerDay.push(matchesOnDate);

      return memo;
    },
    { transactionsPerDay: [], matchesPerDay: [] },
  );

  meta.transactionCount = meta.transactionsPerDay.reduce((a, b) => a + b, 0);
  meta.matchCount = meta.matchesPerDay.reduce((a, b) => a + b, 0);

  return { data: Object.values(collection), meta };
}

export const formatErrorsRawData = ({ rawData, startDate, endDate }) => {
  const records = csvToObjectsWithDate(rawData);

  let errorTypes = new Set();
  records.forEach(r => errorTypes.add(r.error_type));

  let collection = buildObjectByDate(startDate, endDate, function () {
    return {
      errorsData: Object.fromEntries(Array.from(errorTypes).map(errorType => [errorType, 0])),
    };
  });

  records.forEach(record => {
    if (collection[record.date]) {
      let memo = collection[record.date].errorsData;
      memo[record.error_type] += Number(record.error_count);
    }
  });

  const meta = Object.values(collection).reduce(
    (memo, { /* date, */ errorsData }) => {
      const sumOfErrorsOnDate = Object.values(errorsData).reduce((a, b) => a + b, 0);
      memo.errorsPerDay.push(sumOfErrorsOnDate);
      memo.errorCount += sumOfErrorsOnDate;

      return memo;
    },
    { errorsPerDay: [], errorCount: 0, errorTypes },
  );

  return {
    data: Object.values(collection),
    meta: meta,
  };
};

export const formatAverageResponseTimeRawData = ({ rawData, startDate, endDate, channelsIds }) => {
  let collection = buildObjectByDate(startDate, endDate, function () {
    return {
      channelsData: channelsIds.reduce((memo, id) => {
        memo[id] = { averageResponseTime: 0 };
        return memo;
      }, {}),
    };
  });

  const records = csvToObjectsWithDate(rawData);

  records.forEach(record => {
    if (collection[record.date]) {
      let memo = collection[record.date].channelsData[record.channel_id];

      if (memo) {
        const averageResponseTime = roundNumber(Number(record.average_response_time));

        // TODO - manta's returning recrods grouped by mode, so there could be more than one
        //        for the same channel, on the same day. we could average the averages, but
        //        it's easier just to report the worst case scenario.
        memo.averageResponseTime = Math.max(memo.averageResponseTime, averageResponseTime);
      }
    }
  });

  let meta = Object.values(collection).reduce(
    (memo, { /* date, */ channelsData }) => {
      const averageResponseTimeOnDate = getAverageResponseTime(
        Object.values(channelsData).map(channel => channel.averageResponseTime),
      );

      memo.averageResponseTimePerDay.push(averageResponseTimeOnDate);

      return memo;
    },
    { averageResponseTimePerDay: [] },
  );
  meta.totalAverageResponseTime = getAverageResponseTime(meta.averageResponseTimePerDay);

  return {
    data: Object.values(collection),
    meta: meta,
  };
};
