import { sitemap } from "../../constants/sitemap";

const normalizeSitemaptoLinksPairs = array => {
  let links = {};

  array.map(object => {
    links[object.id] = object.path;
  });

  return links;
};

export const linksConstants = normalizeSitemaptoLinksPairs(sitemap);
