export const deepFlat = arr =>
  arr.reduce((acc, val) => (Array.isArray(val) ? acc.concat(deepFlat(val)) : acc.concat(val)), []);

export const formatAsPercent = number => `${number.toFixed(2)}%`;

export const trimTrailingZeroes = value => {
  return Number(value).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export const uniq = nonUniqArray => Array.from(new Set(nonUniqArray));

export const uniqBy = (arr, property) => {
  const uniqProperties = uniq(arr.map(el => el[property]));

  return uniqProperties.map(uniqProperty => arr.find(el => el[property] === uniqProperty));
};

export const downloadFile = (url, filename) => {
  if (!url) {
    return false;
  }

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);

  a.click();
  a.parentElement.removeChild(a);

  return true;
};

export const toArray = value => {
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  } else {
    return [value];
  }
};

export const notEmptyArray = value => {
  return value && Array.isArray(value) && value.length > 0;
};

export const notEmptyObject = obj => {
  return obj && Object.getPrototypeOf(obj) === Object.prototype && Object.keys(obj).length > 0;
};

export const roundNumber = (number, decimalPoint = 1) => {
  return Math.round((Number(number) + Number.EPSILON) * (decimalPoint * 10)) / (decimalPoint * 10);
};
