import { instanceOf } from "prop-types";

export const getUTCDate = date => {
  let dateObj;
  if (instanceOf(date) === Date) {
    dateObj = date;
  } else {
    dateObj = new Date(date);
  }

  return new Date(
    dateObj.getUTCFullYear(),
    dateObj.getUTCMonth(),
    dateObj.getUTCDate(),
    dateObj.getUTCHours(),
    dateObj.getUTCMinutes(),
    dateObj.getUTCSeconds(),
  );
};

export const createDateFromString = dateString => {
  const dateArr = dateString.split("-");
  const year = parseInt(dateArr[0]);
  const month = parseInt(dateArr[1]) - 1;
  const day = parseInt(dateArr[2]);
  return new Date(year, month, day);
};
