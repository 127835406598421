/**
 * This function creates testId based on root string adds modifiers.
 * For now, it only accepts single modifier as a string
 * TODO: Make it accept an array of modifiers and make it work like clsx lib does
 * @param {string|null} root
 * @param {string} [modifiers]
 * @returns {string}
 */
export const createTestId = (root, modifiers) => {
  if (root) {
    return `${root}${modifiers ? `-${modifiers}` : ""}`;
  }
};
