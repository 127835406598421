import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userThunks } from "../user/userSlice";
import { serializeError } from "../../utils/serializeError";
import { settingsApi } from "../../api/settingsApi";

export const apiKeyTypes = {
  PRODUCTION: "PRODUCTION",
  TEST: "TEST",
};

const initialState = {
  roles: {
    entities: {},
    ids: [],
  },
  usersSettings: {
    isLoading: false,
    error: null,
    users: {
      entities: {},
      ids: [],
    },
  },
  apiKeys: {
    isLoading: false,
    error: null,
    apiKeys: {
      entities: {},
      ids: [],
    },
  },
};

// --- THUNKS START ---
const fetchUsersSettingsData = createAsyncThunk(
  "settings/fetchUsersSettingsData",
  async (payload, thunkAPI) => {
    const { data: usersData } = await settingsApi.getManageableUsers();
    const { data: rolesData } = await settingsApi.getAccessibleRoles();
    thunkAPI.dispatch(settingsActions.setUsers(usersData.response));
    thunkAPI.dispatch(settingsActions.setRoles(rolesData));
  },
  {
    serializeError: serializeError,
    condition(arg, api) {
      const { settings } = api.getState();
      if (settings.usersSettings.isLoading) {
        return false;
      }
    },
  },
);

const updateUser = createAsyncThunk(
  "settings/updateUser",
  async (payload, thunkAPI) => {
    const { userId, userData } = payload;
    const { data } = await settingsApi.updateUserInOrg({
      userId,
      userData,
    });
    thunkAPI.dispatch(
      settingsActions.updateUser({
        userId,
        userData: data.response.user,
      }),
    );
  },
  {
    serializeError: serializeError,
  },
);

const fetchApiKeysData = createAsyncThunk(
  "settings/fetchApiKeysData",
  async (payload, thunkAPI) => {
    const { data: apiKeysData } = await settingsApi.getApiKeys();
    const { data: testApiKeysData } = await settingsApi.getTestApiKeys();
    const { data: rolesData } = await settingsApi.getAccessibleRoles();
    thunkAPI.dispatch(
      settingsActions.setApiKeys([
        ...apiKeysData.map(apiKey => {
          return {
            ...apiKey,
            type: apiKeyTypes.PRODUCTION,
          };
        }),
        ...testApiKeysData.map(testApiKey => {
          return {
            ...testApiKey,
            type: apiKeyTypes.TEST,
          };
        }),
      ]),
    );
    thunkAPI.dispatch(settingsActions.setRoles(rolesData));
  },
  {
    serializeError: serializeError,
    condition(arg, api) {
      const { settings } = api.getState();
      if (settings.apiKeys.isLoading) {
        return false;
      }
    },
  },
);

export const settingsThunks = {
  fetchUsersSettingsData,
  updateUser,
  fetchApiKeysData,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // TODO: remove duplicate code, create nice immer functions for updating the data
    setUsers(state, action) {
      if (Array.isArray(action.payload) && action.payload.length) {
        state.usersSettings.users.entities = {};
        state.usersSettings.users.ids = [];
        action.payload.forEach(user => {
          state.usersSettings.users.entities[user.id] = user;
          state.usersSettings.users.ids.push(user.id);
        });
      }
    },
    updateUser(state, action) {
      const { userId, userData } = action.payload;
      if (state.usersSettings.users.entities[userId]) {
        state.usersSettings.users.entities[userId] = {
          ...state.usersSettings.users.entities[userId],
          ...userData,
        };
      }
    },
    setRoles(state, action) {
      if (Array.isArray(action.payload) && action.payload.length) {
        state.roles.entities = {};
        state.roles.ids = [];
        action.payload.forEach(user => {
          state.roles.entities[user.id] = user;
          state.roles.ids.push(user.id);
        });
      }
    },
    setApiKeys(state, action) {
      if (Array.isArray(action.payload) && action.payload.length) {
        state.apiKeys.apiKeys.entities = {};
        state.apiKeys.apiKeys.ids = [];
        action.payload.forEach(user => {
          state.apiKeys.apiKeys.entities[user.id] = user;
          state.apiKeys.apiKeys.ids.push(user.id);
        });
      }
    },
  },
  extraReducers: {
    [fetchUsersSettingsData.pending]: state => {
      state.usersSettings.isLoading = true;
      state.usersSettings.error = null;
    },
    [fetchUsersSettingsData.fulfilled]: state => {
      state.usersSettings.isLoading = false;
      state.usersSettings.error = null;
    },
    [fetchUsersSettingsData.rejected]: (state, action) => {
      state.usersSettings.isLoading = false;
      state.usersSettings.error = action.error;
    },
    [fetchApiKeysData.pending]: state => {
      state.apiKeys.isLoading = true;
      state.apiKeys.error = null;
    },
    [fetchApiKeysData.fulfilled]: state => {
      state.apiKeys.isLoading = false;
      state.apiKeys.error = null;
    },
    [fetchApiKeysData.rejected]: (state, action) => {
      state.apiKeys.isLoading = false;
      state.apiKeys.error = action.error;
    },
    [userThunks.logoutUser.fulfilled]: () => {
      return initialState;
    },
  },
});

export const settingsActions = {
  ...actions,
};

export default reducer;
