export const genericColors = [
  "#17009A",
  "#BAB9C1",
  "#A196E6",
  "#78F5DE",
  "#170A4D",
  "#334D76",
  "#E6F9F6",
  "#8B85A6",
];

export const generateRandomHexColor = () => {
  return "#000000".replace(/0/g, function () {
    return (~~(Math.random() * 16)).toString(16);
  });
};

export const generateColorByIndex = i => {
  if (i !== undefined) {
    return genericColors[Number(i)] || generateRandomHexColor();
  }
  return generateRandomHexColor();
};
