import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils/serializeError";
import { apiTemplatesCatalogApi } from "../../api/apiTemplatesCatalogApi";
import Axios from "axios";

const initialState = {
  apiTemplates: {
    fetchStatus: "IDLE",
    error: null,
    data: {
      entities: {},
      ids: [],
    },
    searchParams: {
      api_templates_page: 1,
      search: "",
      diligence_tags: [],
      all: false,
      sort: "",
      direction: "",
      categories: [],
      regions: [],
      data_source: "",
      tags: [],
    },
    totalPages: 1,
    totalEntries: 0,
  },
  showFilters: false,
};

// --- THUNKS START ---
const fetchApiTemplates = createAsyncThunk(
  "catalog/fetchApiTemplates",
  async (payload, thunkAPI) => {
    const { apiTemplatesCatalog } = thunkAPI.getState();

    const source = Axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });

    // validate all params from qs;
    const params = {
      search: payload.search || initialState.apiTemplates.searchParams.search,
      all: payload.all || initialState.apiTemplates.searchParams.all,
      sort: payload.sort || initialState.apiTemplates.searchParams.sort,
      diligence_tags:
        payload.diligence_tags || initialState.apiTemplates.searchParams.diligence_tags,
      direction: payload.direction || initialState.apiTemplates.searchParams.direction,
      categories: payload.categories || initialState.apiTemplates.searchParams.categories,
      regions: payload.regions || initialState.apiTemplates.searchParams.regions,
      data_source: payload.data_source || initialState.apiTemplates.searchParams.data_source,
      page: payload.api_templates_page || initialState.apiTemplates.searchParams.api_templates_page,
      tags: payload.tags || initialState.apiTemplates.searchParams.tags,
    };

    // Data should be fetched only when params are different from what's already in Redux
    // or if there are no apiTemplates
    const shouldFetch =
      Object.entries(params).some(entry => {
        return (
          entry[1]?.toString() !==
          apiTemplatesCatalog.apiTemplates.searchParams[entry[0]]?.toString()
        );
      }) || apiTemplatesCatalog.apiTemplates.data.ids.length === 0;

    if (shouldFetch) {
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(apiTemplatesCatalogActions.setApiTemplatesLoading());
        thunkAPI.dispatch(apiTemplatesCatalogActions.setApiTemplatesSearchParams(params));
      }
      const { data } = await apiTemplatesCatalogApi.getAPITemplates(params, {
        cancelToken: source.token,
      });
      // That fragment exists solely to remove Blank recipe from results,
      // The same one exists in SearchBar.jsx
      let dataCopy = JSON.parse(JSON.stringify(data));
      if (dataCopy.api_templates.findIndex(item => item.name === "Blank") !== -1) {
        dataCopy.api_templates = dataCopy.api_templates.filter(item => item.name !== "Blank");
        dataCopy.pagination.total_entries -= 1;
      }
      // End of that specific fragment
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(apiTemplatesCatalogActions.setApiTemplates(dataCopy));
      }
    }
  },
  {
    serializeError: serializeError,
  },
);

export const apiTemplatesCatalogThunks = {
  fetchApiTemplates,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "apiTemplatesCatalog",
  initialState,
  reducers: {
    setApiTemplates(state, action) {
      const { api_templates: apiTemplates, pagination } = action.payload;
      // set data
      state.apiTemplates.data.entities = {};
      state.apiTemplates.data.ids = [];
      apiTemplates.forEach(apiTemplate => {
        state.apiTemplates.data.entities[apiTemplate.id] = apiTemplate;
        state.apiTemplates.data.ids.push(apiTemplate.id);
      });
      // set pagination
      state.apiTemplates.totalPages = pagination.total_pages;
      state.apiTemplates.totalEntries = pagination.total_entries;
    },
    setSearchString(state, action) {
      state.apiTemplates.searchString = action.payload;
    },
    setApiTemplatesSearchParams(state, action) {
      state.apiTemplates.searchParams = action.payload;
    },
    updateApiTemplatesSearchParams(state, action) {
      const { param, value } = action.payload;
      state.apiTemplates.searchParams[param] = value;
    },
    setApiTemplatesLoading(state) {
      state.apiTemplates.fetchStatus = "PENDING";
      state.apiTemplates.error = null;
    },
    setShowFilters(state, action) {
      state.showFilters = action.payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: {
    [fetchApiTemplates.fulfilled]: state => {
      state.apiTemplates.fetchStatus = "SUCCESS";
      state.apiTemplates.error = null;
    },
    [fetchApiTemplates.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        return;
      }
      state.apiTemplates.fetchStatus = "FAILED";
      state.apiTemplates.error = action.error;
    },
  },
});

export const apiTemplatesCatalogActions = {
  ...actions,
};

export default reducer;
