import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { userApi } from "../../api/userApi";
import { operationalDashboardApi } from "../../api/operationalDashboardApi";
import { serializeError } from "../../utils/serializeError";

const initialState = {
  isUserLoggedIn: false,
  userData: {},
  blackfinJWT: null,
  isUserDataFetching: false,
  userDataFetchingError: null,
  showDevtools: false,
  contactUsModalOpen: false,
  // for explanation of this flag go here: https://github.com/DemystData/autodisco/pull/1136
  hasBeenLoggedOutManually: false,
  lastLoggedOutPage: null,
  hasSigned: false,
};

// --- THUNKS START ---

const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (payload, thunkAPI) => {
    const { data } = await userApi.getUserData();

    thunkAPI.dispatch(
      userActions.setUserData({
        userData: data,
      }),
    );
  },
  {
    serializeError: serializeError,
  },
);

const logoutUser = createAsyncThunk("user/logoutUser", async (payload, thunkAPI) => {
  try {
    await userApi.logoutUser();
  } catch (e) {
    console.error(e);
  }
  thunkAPI.dispatch(userActions.logoutUser());
});

const logoutAdminUserUsingAccountInClientOrganization = createAsyncThunk(
  "user/logoutUser",
  async ({ organization_id, password }, thunkAPI) => {
    try {
      await userApi.logoutAdminUserUsingAccountInClientOrganization(organization_id, password);
    } catch (e) {
      console.error(e);
    }
    thunkAPI.dispatch(userActions.logoutUser());
  },
);

const updateUserCreditBalance = createAsyncThunk(
  "user/updateUserCreditBalance",
  async (payload, thunkAPI) => {
    const { data } = await userApi.getUserData();

    let bill = null;
    if (data.organization.credits_or_caps === "one_credit_to_rule_them_all") {
      bill = await operationalDashboardApi.getCurrentBill();
    }

    thunkAPI.dispatch(
      userActions.updateUserCreditBalance({
        evaluationCredits: data.organization.evaluation_credits,
        productionCredits: data.organization.production_credits,
        creditsSpentThisMonth: bill?.data?.this_month.credits_spent,
      }),
    );
  },
  {
    serializeError: serializeError,
  },
);

export const userThunks = {
  fetchUserData,
  logoutUser,
  logoutAdminUserUsingAccountInClientOrganization,
  updateUserCreditBalance,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(state, action) {
      const { userData } = action.payload;
      state.userData = userData;
      state.isUserLoggedIn = true;
      state.hasBeenLoggedOutManually = false;
    },
    setBlackfinJWT(state, action) {
      state.blackfinJWT = action.payload;
    },
    setContactUsModalState(state, action) {
      state.contactUsModalOpen = action.payload;
    },
    setLastLoggedOutPage(state, action) {
      const { lastLoggedOutPage } = action.payload;
      state.lastLoggedOutPage = lastLoggedOutPage;
    },
    setHasSigned(state, action) {
      state.hasSigned = action.payload;
    },
    setHasProvidedBilling(state, action) {
      state.hasProvidedBilling = action.payload;
    },
    setHasRequestedAgreement(state, action) {
      state.hasRequestedAgreement = action.payload;
    },
    setHasApproval(state, action) {
      state.hasApproval = action.payload;
    },
    logoutUser(state) {
      const loggedOutState = {
        isUserLoggedIn: false,
        userData: {},
        blackfinJWT: null,
        isUserDataFetching: false,
        userDataFetchingError: null,
        showDevtools: false,
        contactUsModalOpen: false,
        // for explanation of this flag go here: https://github.com/DemystData/autodisco/pull/1136
        hasBeenLoggedOutManually: false,
        lastLoggedOutPage: state.lastLoggedOutPage || null,
        hasSigned: false,
      };
      return loggedOutState;
    },
    updateOrganization(state, action) {
      state.userData.organization = action.payload;
    },
    updateUserCreditBalance(state, action) {
      state.userData.organization.evaluation_credits = action.payload.evaluationCredits;
      state.userData.organization.production_credits = action.payload.productionCredits;
      state.userData.organization.credits_spent_this_month = action.payload.creditsSpentThisMonth;
    },
  },
  extraReducers: {
    [fetchUserData.pending]: state => {
      state.isUserDataFetching = true;
    },
    [fetchUserData.fulfilled]: state => {
      state.isUserDataFetching = false;
    },
    [fetchUserData.rejected]: (state, action) => {
      state.isUserDataFetching = false;
      state.userData = initialState.userData;
      state.isUserLoggedIn = initialState.isUserLoggedIn;
      state.hasBeenLoggedOutManually = false;
      state.userDataFetchingError = action.error || null;
    },
  },
});

export const userActions = {
  ...actions,
};

export default reducer;
