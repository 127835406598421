import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils/serializeError";
import { apiTemplatesCatalogApi } from "../../api/apiTemplatesCatalogApi";
import Axios from "axios";

const initialState = {
  apiFeaturedTemplates: {
    fetchStatus: "IDLE",
    error: null,
    data: {
      entities: {},
      ids: [],
    },
  },
};

// --- THUNKS START ---
const fetchApiFeaturedTemplates = createAsyncThunk(
  "catalog/fetchApiFeaturedTemplates",
  async (payload, thunkAPI) => {
    const { apiFeaturedTemplatesCatalog } = thunkAPI.getState();

    const source = Axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });

    // Data should be fetched only if there are no apiFeaturedTemplates
    const shouldFetch = apiFeaturedTemplatesCatalog.apiFeaturedTemplates.data.ids.length === 0;

    if (shouldFetch) {
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(apiFeaturedTemplatesCatalogActions.setApiFeaturedTemplatesLoading());
      }
      const { data } = await apiTemplatesCatalogApi.getFeaturedAPITemplates({
        cancelToken: source.token,
      });
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(apiFeaturedTemplatesCatalogActions.setApiFeaturedTemplates(data));
      }
    }
  },
  {
    serializeError: serializeError,
  },
);

export const apiFeaturedTemplatesCatalogThunks = {
  fetchApiFeaturedTemplates,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "apiFeaturedTemplatesCatalog",
  initialState,
  reducers: {
    setApiFeaturedTemplates(state, action) {
      const apiTemplates = action.payload;
      // set data
      state.apiFeaturedTemplates.data.entities = {};
      state.apiFeaturedTemplates.data.ids = [];
      apiTemplates.forEach(apiTemplate => {
        state.apiFeaturedTemplates.data.entities[apiTemplate.id] = apiTemplate;
        state.apiFeaturedTemplates.data.ids.push(apiTemplate.id);
      });
    },
    setApiFeaturedTemplatesLoading(state) {
      state.apiFeaturedTemplates.fetchStatus = "PENDING";
      state.apiFeaturedTemplates.error = null;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: {
    [fetchApiFeaturedTemplates.fulfilled]: state => {
      state.apiFeaturedTemplates.fetchStatus = "SUCCESS";
      state.apiFeaturedTemplates.error = null;
    },
    [fetchApiFeaturedTemplates.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        return;
      }
      state.apiFeaturedTemplates.fetchStatus = "FAILED";
      state.apiFeaturedTemplates.error = action.error;
    },
  },
});

export const apiFeaturedTemplatesCatalogActions = {
  ...actions,
};

export default reducer;
