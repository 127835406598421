import { useState, useEffect } from "react";

import DOMPurify from "isomorphic-dompurify";

import TagManager from "react-gtm-module";

import { appConfig, cookiesConfig } from "./appConfig";
import usePathChange from "../utils/hooks/usePathChange";

import { useUser } from "../redux/Provider";

let userTrackingDebug = appConfig.USER_TRACKING_DEBUG && isUserTrackingVisible();

function isUserTrackingVisible() {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem("user_tracking_visible") === "true";
  }
  return false;
}

function filterParameters(body) {
  for (let param in body) {
    if (filterParameters.FILTERS.includes(param)) {
      body[param] = filterParameters.MASK;
    } else if (
      body[param] !== null &&
      !Array.isArray(body[param]) &&
      typeof body[param] === "object"
    ) {
      filterParameters(body[param]);
    }
  }
}

filterParameters.FILTERS = [
  "password",
  "password_confirmation",
  "passwordConfirmation",
  "qr_code",
  "qrCode",
  "code",
];

filterParameters.MASK = "[FILTERED]";

const getCookies = () => {
  if (typeof window === undefined) return {};
  return document.cookie
    ?.split(";")
    .map(v => v.split("="))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(v[1]?.trim());
      return acc;
    }, {});
};

export const initUserTracking = () => {
  if (getCookies()["_manta_cookies"] !== "necessary_only") {
    userTrackingDebug && console.log(`Google Analytics running.`);
    TagManager.initialize({ gtmId: appConfig.TAG_MANAGER_ID });
    sessionStorage.setItem("userTracking", true);
  }
};

export const isDemystUser = email => {
  return email.includes("@demystdata.com");
};

export const setUserToTrack = userData => {
  if (getCookies()["_manta_cookies"] !== "necessary_only") {
    if (userData.user && userData.organization) {
      setGaDimension({
        dimension2: "True",
        dimension4: isDemystUser(userData.user.email) ? "True" : "False",
        dimension6: userData.organization.name,
        dimension7: getCookies()[cookiesConfig?.homepage?.cookieName] || "Undefined",
        dimension8: getCookies()[cookiesConfig?.cta?.cookieName] || "Undefined",
      });
    } else {
      setGaDimension({
        dimension7: getCookies()[cookiesConfig?.homepage?.cookieName] || "Undefined",
        dimension8: getCookies()[cookiesConfig?.cta?.cookieName] || "Undefined",
      }); // set empty
    }
  }
};

export const trackPageView = (pathname, title) => {
  if (getCookies()["_manta_cookies"] !== "necessary_only") {
    const titleToSend = title || "Marketing";
    const path = pathname
      ? pathname + window.location.search
      : window.location.pathname + window.location.search;
    const cleanPath = DOMPurify.sanitize(path);

    userTrackingDebug && console.log(`Page view: ${cleanPath}`);

    if (titleToSend !== "Marketing" && appConfig.IS_PRODUCTION) {
      const _hsq = typeof window !== "undefined" && window._hsq ? window._hsq : [];
      _hsq.push(["setPath", cleanPath]);
      _hsq.push(["trackPageView"]);
    }
  }
};

export const sendGaEvent = (config, email) => {
  const eventConfig = ({
    action = "",
    category = "",
    label = email ? (isDemystUser(email) ? "Demyst" : "Non Demyst") : "",
  }) => ({
    action,
    category,
    label,
  });

  if (getCookies()["_manta_cookies"] !== "necessary_only") {
    const configToSend = eventConfig(config || {});
    window.gtag("event", configToSend.action, {
      event_category: configToSend.category,
      event_label: configToSend.label,
    });
  }
};

/* tracks user info scoped to session */
export const setGaDimension = config => {
  const dimensionConfig = ({
    dimension2 = "False",
    dimension4 = "False",
    dimension6 = "Anonymous",
    dimension7 = "Undefined",
    dimension8 = "Undefined",
  }) => ({
    dimension2, // is the user Logged in?
    dimension4, // is the user from a demyst account?
    dimension6, // what is the users org name?
    dimension7, // homepage a/b test
    dimension8, // main cta a/b test
  });

  if (getCookies()["_manta_cookies"] !== "necessary_only") {
    const configToSend = dimensionConfig(config || {});
    window.gtag("set", configToSend);
  }
};

export const setTrackingStorage = pathname => {
  const rightNow = new Date().toLocaleString("en-US");

  if (pathname) {
    if (localStorage.getItem("lastPage")) {
      localStorage.removeItem("lastPage");
      localStorage.removeItem("blogPostViewed");
      localStorage.removeItem("gaAd");
      localStorage.removeItem("current_page_path");
      localStorage.removeItem("demystFirstVisit");
    }

    if (localStorage.getItem("page_flow")) {
      let pathsArray = JSON.parse(localStorage.getItem("page_flow"));
      pathsArray.push(pathname);
      localStorage.setItem("page_flow", JSON.stringify(pathsArray.slice(-10)));

      if (localStorage.getItem("current_page_path_datetime")) {
        localStorage.setItem(
          "prev_page_path_datetime",
          localStorage.getItem("current_page_path_datetime"),
        );
      }
      localStorage.setItem("prev_page_path", pathsArray.reverse()[1]);
    } else {
      let pathsArray = [];
      pathsArray[0] = pathname;
      if (!localStorage.getItem("landing_page")) {
        localStorage.setItem("landing_page_datetime", rightNow);
      }
      localStorage.setItem("landing_page", pathname);
      localStorage.setItem("landing_page_full_url", window.location.href);
      localStorage.setItem("page_flow", JSON.stringify(pathsArray));
    }

    if (sessionStorage.getItem("session_page_flow")) {
      let pathsArray = JSON.parse(sessionStorage.getItem("session_page_flow"));
      pathsArray.push(pathname);
      sessionStorage.setItem("session_page_flow", JSON.stringify(pathsArray.slice(-10)));
    } else {
      let pathsArray = [];
      pathsArray[0] = pathname;
      sessionStorage.setItem("session_page_flow", JSON.stringify(pathsArray));
    }

    const allCookies = Object.entries(cookiesConfig)
      .map(group => group[0] !== "deprecated" && group[1].cookieName)
      .filter(Boolean);

    allCookies?.map(cookie => {
      let cookieVal = getCookies()[cookie];
      if (cookieVal) {
        if (!localStorage.getItem(cookie)) {
          localStorage.setItem(cookie, cookieVal);
        }
        userTrackingDebug && console.log(`${cookie} found in cookies`);
      }
    });

    const deprecatedCookies = Object.entries(cookiesConfig)
      .map(group => group[0] === "deprecated" && group[1])
      .filter(Boolean)
      .flat(1);

    deprecatedCookies.map(cookie => {
      let cookieVal = getCookies()[cookie];

      if (cookieVal) {
        document.cookie = `${cookie}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        localStorage.removeItem(cookie);
      }
    });

    localStorage.setItem("current_page_path", pathname);
    localStorage.setItem("current_page_path_datetime", rightNow);
  }
};

export const setTrackingFromParamsStorage = params => {
  const rightNow = new Date().toLocaleString("en-US");

  const searchParams = params
    ?.slice(1)
    .split("&")
    .map(p => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

  if (searchParams?.li_fat_id) {
    if (!localStorage.getItem("li_fat_id")) {
      localStorage.setItem("li_fat_id_created", rightNow);
    }
    localStorage.setItem("li_fat_id", searchParams.li_fat_id);
    localStorage.setItem("li_fat_id_updated", rightNow);
    userTrackingDebug && console.log(`li_fat_id found in url parameters`);
  }

  if (searchParams?.gaAd) {
    if (!localStorage.getItem("gaAd")) {
      localStorage.setItem("gaAd_created", rightNow);
    }
    localStorage.setItem("gaAd", searchParams.gaAd);
    localStorage.setItem("gaAd_updated", rightNow);
    userTrackingDebug && console.log(`gaAd found in url parameters`);
  }

  if (searchParams?.gclid) {
    if (!localStorage.getItem("gclid")) {
      localStorage.setItem("gclid_created", rightNow);
    }
    localStorage.setItem("gclid", searchParams.gclid);
    localStorage.setItem("gclid_updated", rightNow);
    userTrackingDebug && console.log(`gclid found in url parameters`);
  }

  ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"].map(utm => {
    if (searchParams?.[utm]) {
      if (!localStorage.getItem(utm)) {
        localStorage.setItem(`${utm}_created`, rightNow);
      }
      localStorage.setItem(utm, searchParams?.[utm]);
      localStorage.setItem(`${utm}_updated`, rightNow);
      userTrackingDebug && console.log(`${utm} found in url parameters`);
    }
  });

  return userTrackingDebug && console.log("User localStorage set from url parameters");
};

export const UserTracking = () => {
  const pathname = usePathChange();

  const userData = useUser();

  const [ud, setUd] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState(false);

  useEffect(() => {
    initUserTracking();
    setTrackingEnabled(true);
    userTrackingDebug && console.log("UserTracking is running");
  }, []);

  useEffect(() => {
    setUd(userData);
  }, [userData]);

  useEffect(() => {
    if (pathname) {
      const cleanPathname = DOMPurify.sanitize(pathname);
      setTrackingStorage(cleanPathname);
      setTrackingFromParamsStorage(window.location?.search);
      !cleanPathname.startsWith("/app") && trackPageView(cleanPathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (ud && trackingEnabled) {
      Object.keys(ud).length !== 0 ? setUserToTrack(ud) : setUserToTrack({});

      return () => {
        if (userTrackingDebug) {
          Object.keys(ud).length !== 0
            ? console.log(`User has been identified as ${ud.user?.email}`)
            : console.log("User is anonymous");
        }
      };
    }
  }, [ud, trackingEnabled]);

  return null;
};
