import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { serializeError } from "../../utils/serializeError";
import { projectsCatalogApi } from "../../api/projectsCatalogApi";

const initialState = {
  project: {},
  isSingleProjectFetching: false,
  singleProjectFetchingError: null,
};

// --- THUNKS START ---

const fetchSingleProject = createAsyncThunk(
  "project/fetchSingleProject",
  async (payload, thunkAPI) => {
    const { data } = await projectsCatalogApi.getSingleProject(payload);

    thunkAPI.dispatch(
      singleProjectActions.setSingleProject({
        project: data.project,
      }),
    );
  },
  {
    serializeError: serializeError,
  },
);

export const singleProjectThunks = {
  fetchSingleProject,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "project",
  initialState,
  reducers: {
    setSingleProject(state, action) {
      const { project } = action.payload;
      state.project = project;
    },
  },
  extraReducers: {
    [fetchSingleProject.pending]: state => {
      state.isSingleProjectFetching = true;
    },
    [fetchSingleProject.fulfilled]: state => {
      state.isSingleProjectFetching = false;
    },
    [fetchSingleProject.rejected]: (state, action) => {
      state.isSingleProjectFetching = false;
      state.singleProjectFetchingError = action.error || null;
    },
  },
});

export const singleProjectActions = {
  ...actions,
};

export default reducer;
