const alwaysLowercase = [
  "a",
  "an",
  "and",
  "by",
  "but",
  "for",
  "in",
  "nor",
  "of",
  "or",
  "so",
  "the",
  "to",
  "yet",
];

const alwaysUppercase = [
  "ABN",
  "ABR",
  "ACN",
  "API",
  "ASIC",
  "B2B",
  "CIBI",
  "DBT",
  "EASI",
  "HTML",
  "ID",
  "IFI",
  "IHS",
  "IP",
  "IXI",
  "JSON",
  "KYC",
  "KYB",
  "MDS",
  "MYOB",
  "NHTSA",
  "NOAA",
  "SBCS",
  "UK",
  "US",
  "USA",
  "UV",
  "XML",
];

const customCase = [
  "AccountRight",
  "AggData",
  "AnalyticsIQ",
  "AustralianBusinessRegister",
  "BuildZoom",
  "CarsXE",
  "CollegeData",
  "CreditMantri",
  "CreditorWatch",
  "DataZoo",
  "DemystData",
  "EmailAge",
  "EpiAnalytics",
  "EveryoneAPI",
  "FactSet",
  "FraudPoint",
  "FullContact",
  "GlobalDatabase",
  "HDScores",
  "HarvardBusinessPublishing",
  "HazardHub",
  "HouseCanary",
  "IGoDirect",
  "InstaTouch",
  "IntellData",
  "JobsPikr",
  "JsonWhoIs",
  "JustClickHere",
  "LexisNexis",
  "ManifestDB",
  "MarketCheck",
  "MassachusettsMedicalSociety",
  "MaxMind",
  "OpenCorporates",
  "OpenWeather",
  "PhoneID",
  "PropertyGuru",
  "QuickBooks",
  "RepRisk",
  "RocketReach",
  "SafeGraph",
  "SambaSafety",
  "SentiLink",
  "SmartyStreets",
  "TLOxp",
  "TVEyes",
  "TowerData",
  "TransUnion",
  "VINOptions",
  "WhoIs",
  "WhoisXML",
  "ZeroBounce",
];

const isString = string => string && typeof string === "string";

const upperCaseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Formats words to natural-cased word.
 * Examples: customer -> Customer, id => ID, the => the
 *
 * @param {string} string Source string.
 * @returns {String} Natural-cased string.
 */
export const toNaturalWord = string => {
  if (!isString(string)) {
    return string;
  }

  if (alwaysLowercase.includes(string.toLowerCase())) return string.toLowerCase();
  if (alwaysUppercase.includes(string.toUpperCase())) return string.toUpperCase();
  const customCaseString = customCase.find(
    customCaseEl => customCaseEl.toLowerCase() === string.toLowerCase(),
  );
  if (customCaseString) return customCaseString;

  return upperCaseFirstLetter(string);
};

/**
 * Formats sentences or some_underscored_ids to natural-cased sentences.
 * Example: customer_id_to_username -> Customer ID to Username
 *
 * @param {string} string Source string.
 * @returns {String} Natural-cased string.
 */
export const toNaturalSentence = string => {
  if (!isString(string)) {
    return string;
  }

  return string
    .split(/[\s_]/)
    .map((word, index) => (index ? toNaturalWord(word) : upperCaseFirstLetter(toNaturalWord(word))))
    .join(" ");
};

export const getInitials = (name = "") =>
  name &&
  name
    .split(/\s/)
    .map(w => w[0])
    .join("");

export const capitalize = string => {
  if (typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};
