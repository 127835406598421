import { blackfinApi, demystConsoleApi } from "./api";

export const settingsApi = {
  async getManageableUsers() {
    return await demystConsoleApi.get("/manageable_users", {
      withCredentials: true,
    });
  },

  async inviteUser({ email, roleId, fullName }) {
    return await demystConsoleApi.post(
      `/users/invitation`,
      {
        user: {
          email,
          role_id: roleId,
          full_name: fullName,
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async inviteUserToken() {
    return await demystConsoleApi.post(
      `/organization/invitation_token`,
      {},
      {
        withCredentials: true,
      },
    );
  },

  async updateUserInOrg({ userId, userData }) {
    return await demystConsoleApi.put(
      `/organization/users/${userId}`,
      {
        user: userData,
      },
      {
        withCredentials: true,
      },
    );
  },

  async getAccessibleRoles() {
    return await demystConsoleApi.get("/organization/accessible_roles", {
      withCredentials: true,
    });
  },

  async updateCurrentOrganization(organizationData) {
    return await demystConsoleApi.put(
      "/organization",
      {
        organization: organizationData,
      },
      { withCredentials: true },
    );
  },

  async createNewSchemaField(data, headers) {
    return await blackfinApi("us").post(
      "/v2/connector-enablement/fetch",
      {
        connector: data.connector,
        inputs: data.inputs,
        credentials: data.credentials || {},
      },
      {
        headers: headers,
      },
    );
  },

  async publishNewSchema({ new_schema, ...body }) {
    return await demystConsoleApi.post(
      new_schema ? "/data_functions/make_tile?new_schema=true" : "/data_functions/make_tile",
      body,
      {
        withCredentials: true,
      },
    );
  },

  async getOrganizationRegions() {
    return await demystConsoleApi.get("/workspaces/get_all_current_organization_regions", {
      withCredentials: true,
    });
  },

  async getApiKeys() {
    return await demystConsoleApi.get("/api_keys", {
      withCredentials: true,
      params: {
        v1: true,
      },
    });
  },

  async getTestApiKeys() {
    return await demystConsoleApi.get("/test_api_keys", {
      withCredentials: true,
      params: {
        v1: true,
      },
    });
  },

  async generateApiKey(name, roleId, expiresAt, v1 = true) {
    return await demystConsoleApi.post(
      "/api_keys",
      {
        name,
        role_id: roleId,
        expires_at: expiresAt,
        v1,
      },
      {
        withCredentials: true,
      },
    );
  },

  async generateTestApiKey(aegean = "aegean", v1 = true) {
    return await demystConsoleApi.post(
      "/test_api_keys",
      {
        aegean,
        v1,
      },
      {
        withCredentials: true,
      },
    );
  },

  async removeApiKey(apiKeyId) {
    return await demystConsoleApi.delete(`/api_keys/${apiKeyId}`, {
      withCredentials: true,
    });
  },

  async removeTestApiKey(apiKeyId, aegean = "aegean") {
    return await demystConsoleApi.delete(`/test_api_keys/${apiKeyId}`, {
      withCredentials: true,
      data: {
        aegean,
      },
    });
  },

  async getGlobalConnectorCredentials() {
    return await demystConsoleApi.get("/credentials.json?detailed=true", {
      withCredentials: true,
    });
  },

  async removeGlobalConnectorCredentials(id) {
    return await demystConsoleApi.delete(`/credentials/${id}`, {
      withCredentials: true,
    });
  },

  async createGlobalConnectorCredentials({ organization_id, credential_key_id, value }) {
    return await demystConsoleApi.post(
      "/credentials",
      {
        organization_id,
        credential_key_id,
        value,
      },
      {
        withCredentials: true,
      },
    );
  },

  async generate2FASecret() {
    return await demystConsoleApi.post(
      "/two_factor_authentication/generate_secret",
      {},
      {
        withCredentials: true,
      },
    );
  },

  async activate2FA(code) {
    return await demystConsoleApi.post(
      "/two_factor_authentication/activate",
      { code },
      {
        withCredentials: true,
      },
    );
  },

  async deactivate2FA() {
    return await demystConsoleApi.post(
      "/two_factor_authentication/deactivate",
      {},
      {
        withCredentials: true,
      },
    );
  },

  async optInToWeeklySummary() {
    return await demystConsoleApi.post(
      "/users/ops_dash_use_case_weekly_summaries",
      {},
      {
        withCredentials: true,
      },
    );
  },

  async optOutFromWeeklySummary() {
    return await demystConsoleApi.delete("/users/ops_dash_use_case_weekly_summaries", {
      withCredentials: true,
    });
  },

  async getOrganizationAgreements() {
    return await demystConsoleApi.get("/organization/agreements.json", { withCredentials: true });
  },

  async getCurrentOrganization() {
    return await demystConsoleApi.get(`/organization.json`, {
      withCredentials: true,
    });
  },

  async getServiceAgreement() {
    return await demystConsoleApi.get(`organization/service_agreement`, {
      withCredentials: true,
    });
  },

  async createServiceAgreement(userId) {
    return await demystConsoleApi.post(
      `organization/service_agreement`,
      {
        service_agreement: {
          signed_by_id: userId,
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async createEnterpriseAgreement(userId) {
    return await demystConsoleApi.post(
      `organization/service_agreement`,
      {
        service_agreement: { enterprise_agreement_requested_by_id: userId },
      },
      {
        withCredentials: true,
      },
    );
  },

  async getIpWhitelist() {
    return await demystConsoleApi.get(`ip_whitelists`, {
      withCredentials: true,
    });
  },

  async addIpToWhitelist({ organization_id, address, guards }) {
    return await demystConsoleApi.post(
      `ip_whitelists`,
      { organization_id, address, guards },
      {
        withCredentials: true,
      },
    );
  },

  async removeIpFromWhitelist(addressId) {
    return await demystConsoleApi.delete(`ip_whitelists/${addressId}`, {
      withCredentials: true,
    });
  },

  async getWebhooks() {
    return await demystConsoleApi.get(`v1/webhooks`, {
      withCredentials: true,
    });
  },

  async updateWebhooks(webhookId, webhookData) {
    return await demystConsoleApi.put(
      `v1/webhooks/${webhookId}`,
      {
        webhook: webhookData,
      },
      {
        withCredentials: true,
      },
    );
  },

  async createWebhooks(webhookData) {
    return await demystConsoleApi.post(
      `v1/webhooks`,
      {
        webhook: webhookData,
      },
      {
        withCredentials: true,
      },
    );
  },

  async getBringYourOwnKeyCredentials() {
    return await demystConsoleApi.get("/bring_your_own_key_credentials", {
      withCredentials: true,
    });
  },

  async destroyBringYourOwnKeyCredential(id) {
    return await demystConsoleApi.delete(`/bring_your_own_key_credentials/${id}`, {
      withCredentials: true,
    });
  },

  async createBringYourOwnKeyCredential(params) {
    return await demystConsoleApi.post("/bring_your_own_key_credentials", params, {
      withCredentials: true,
    });
  },
};
