import { demystConsoleApi } from "./api";

export const projectsCatalogApi = {
  async createProject({ name, description, created_by, status }) {
    return await demystConsoleApi.post(
      `/projects`,
      {
        name,
        description,
        created_by,
        status,
      },
      {
        withCredentials: true,
      },
    );
  },

  async getProjects() {
    return await demystConsoleApi.get(`/projects`, {
      withCredentials: true,
    });
  },

  async getSingleProject(project_id) {
    return await demystConsoleApi.get(`/projects/${project_id}`, {
      withCredentials: true,
    });
  },

  async removeProject(project_id) {
    return await demystConsoleApi.delete(`/projects/${project_id}`, {
      withCredentials: true,
    });
  },

  async getProjectsComments(project_id) {
    return await demystConsoleApi.get(`/projects/${project_id}/comments`, {
      withCredentials: true,
    });
  },

  async postProjectComment({ projectId, body, privacy = "is_public", parent_comment_id = null }) {
    return await demystConsoleApi.post(
      `/projects/${projectId}/comments`,
      {
        body,
        privacy,
        parent_comment_id,
      },
      {
        withCredentials: true,
      },
    );
  },

  async updateComment({
    projectId,
    commentId,
    body,
    privacy = "is_public",
    parent_comment_id = null,
  }) {
    return await demystConsoleApi.put(
      `/projects/${projectId}/comments/${commentId}`,
      {
        body,
        privacy,
        parent_comment_id,
      },
      {
        withCredentials: true,
      },
    );
  },

  async removeProjectComment({ projectId, commentId }) {
    return await demystConsoleApi.delete(`/projects/${projectId}/comments/${commentId}`, {
      withCredentials: true,
    });
  },
};
