import PropTypes from "prop-types";
import clsx from "clsx";
import { createTestId } from "../../../utils/createTestId";

export function Button({
  variant,
  size,
  fullWidth,
  className,
  testId,
  type,
  id,
  onClick,
  children,
  disabled,
  ...props
}) {
  return (
    <button
      className={clsx(
        "Button",
        variant && `Button--${variant}`,
        size && `Button--${size}`,
        disabled && `Button--disabled`,
        fullWidth && `Button--fullWidth`,
        className,
      )}
      data-test-id={createTestId(testId)}
      type={type || "button"}
      id={id}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  testId: PropTypes.string,
  variant: PropTypes.oneOf([
    "cta",
    "outlined",
    "warning",
    "icon",
    "clear",
    "noBorder",
    "secondary",
    "transparent",
    "greyscale",
    "withShadow",
    "withBlueShadow",
    "whiteWithShadow",
  ]),
};
