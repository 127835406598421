import dynamic from "next/dynamic";
const Header = dynamic(() => import("./layouts/LayoutComponents/Header").then(mod => mod.Header));
const Footer = dynamic(() => import("./layouts/LayoutComponents/Footer"));
import { useRouter } from "next/router";

export const Wrappers = {
  EmptyMarketingWrapper: ({ children }) => {
    return (
      <>
        <main className="layout-main uplifted">{children}</main>
      </>
    );
  },

  MarketingWrapper: ({ children }) => {
    const router = useRouter();
    const type = children && children.type ? children.type.name : undefined;
    const pathName = router.pathname;
    const CookieMessage = dynamic(() =>
      import("../platform/components/cookies/CookiesMessage").then(mod => mod.CookieMessage),
    );
    return (
      <>
        {type !== "RequestDemoPage" && pathName !== "/request-demo" && (
          <Header showBanner={false} />
        )}
        <main className="layout-main">
          {children}
          <div className="bottom-content">
            <CookieMessage />
          </div>
        </main>
        {type !== "RequestDemoPage" && pathName !== "/request-demo" && <Footer />}
      </>
    );
  },

  LandingPageWrapper: ({ children }) => {
    const router = useRouter();
    const type = children && children.type ? children.type.name : undefined;
    const pathName = router.pathname;
    const CookieMessage = dynamic(() =>
      import("../platform/components/cookies/CookiesMessage").then(mod => mod.CookieMessage),
    );
    return (
      <>
        {type !== "RequestDemoPage" && pathName !== "/request-demo" && (
          <Header showBanner={false} showNav={false} />
        )}
        <main className="layout-main">
          {children}
          <div className="bottom-content">
            <CookieMessage />
          </div>
        </main>
        {type !== "RequestDemoPage" && pathName !== "/request-demo" && <Footer showNav={false} />}
      </>
    );
  },

  NoWrapper: ({ children }) => {
    return <>{children}</>;
  },
};
