import { blackfinApi, demystConsoleApi } from "./api";

export const productsCatalogApi = {
  async getRecipesUsing(provider, configuration) {
    return await demystConsoleApi.get(`api_templates.json?uses=${provider}`, {
      withCredentials: true,
      ...configuration,
    });
  },

  async getRelatedProvider(providerId, configuration) {
    return await demystConsoleApi.get(`catalog/providers/${providerId}/related`, {
      withCredentials: true,
      ...configuration,
    });
  },

  async getProducts(params, configuration) {
    return await demystConsoleApi.get("catalog/providers", {
      params,
      withCredentials: true,
      ...configuration,
    });
  },

  async getFeaturedProducts() {
    return await demystConsoleApi.get("catalog/providers?featured=true", {
      withCredentials: true,
    });
  },

  async getProduct(id, configuration) {
    return await demystConsoleApi.get(`catalog/providers/${id}`, {
      withCredentials: true,
      ...configuration,
    });
  },

  async getAllDataSources({ regions } = {}) {
    return await demystConsoleApi.get(`catalog/data_sources`, {
      params: {
        regions: regions,
      },
      withCredentials: true,
    });
  },

  async searchForDataSource(searchString, configuration) {
    return await demystConsoleApi.get(`catalog/data_sources`, {
      withCredentials: true,
      params: {
        search: searchString,
      },
      ...configuration,
    });
  },

  async autocomplete(searchString, configuration) {
    return await demystConsoleApi.get(`catalog/autocompletes`, {
      withCredentials: true,
      params: {
        query: searchString,
        attributes: true,
        limit: 5,
      },
      ...configuration,
    });
  },

  async autocompleteOverviewConnectors(searchString, configuration) {
    return await demystConsoleApi.get(`catalog/autocompletes`, {
      withCredentials: true,
      params: {
        query: searchString,
        limit: 50,
        only: ["providers"],
      },
      ...configuration,
    });
  },

  async autocompleteAttributes(searchString, configuration) {
    return await demystConsoleApi.get(`catalog/autocompletes`, {
      withCredentials: true,
      params: {
        query: searchString,
        limit: 50,
        attributes: true,
      },
      ...configuration,
    });
  },

  async getSchemaFields({ ...params }) {
    return await demystConsoleApi.get(`catalog/schema_fields`, {
      withCredentials: true,
      params: {
        per_page: 12,
        ...params,
      },
    });
  },

  async fetchSampleData(tableProviderId) {
    return await demystConsoleApi.post(
      `table_providers/${tableProviderId}/download_sample_data`,
      {
        table_provider: {
          id: tableProviderId,
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async fetchTypes(productId) {
    return await demystConsoleApi.get(`types/${productId}`, {
      withCredentials: true,
    });
  },

  async getExamples({ apiKey, config, inputs, providers, region }) {
    return await blackfinApi(region).post("v2/execute", {
      api_key: apiKey,
      config,
      inputs,
      providers,
    });
  },

  async createApiFromProvider({ providerId, name, description, regionId }) {
    return await demystConsoleApi.post(
      `/catalog/providers/${providerId}/clones`,
      {
        clone: {
          name,
          description,
          region_id: regionId,
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async downloadCatalog() {
    return await demystConsoleApi.get("catalog/providers/render_csv?format=csv", {
      withCredentials: true,
    });
  },

  async getDataRegions() {
    return await demystConsoleApi.get("data_regions", {
      withCredentials: true,
    });
  },

  async getAllTags() {
    return await demystConsoleApi.get("tags", {
      withCredentials: true,
    });
  },

  async updateTableProvider(id, updates) {
    return await demystConsoleApi.patch(
      `/admin/table_providers/${id}/`,
      {
        table_provider: updates,
      },
      {
        withCredentials: true,
      },
    );
  },

  async updateSchemaField(id, updates) {
    return await demystConsoleApi.patch(
      `/admin/schema_fields/${id}/`,
      {
        schema_field: updates,
      },
      {
        withCredentials: true,
      },
    );
  },

  async createTags(category, name) {
    return await demystConsoleApi.post(
      `/admin/tags`,
      {
        tag: {
          name,
          category,
        },
      },
      {
        withCredentials: true,
      },
    );
  },

  async tagsSearch(category, search) {
    return await demystConsoleApi.get(`/admin/tags?category=${category}&search=${search}`, {
      withCredentials: true,
    });
  },
};
