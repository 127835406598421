import Axios from "axios";
import { appConfig } from "../config/appConfig";

export const demystConsoleApi = Axios.create({
  baseURL: appConfig.DEMYST_CONSOLE_API_URL,
  headers: {
    // eslint-disable-next-line prettier/prettier
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const regionalURL = region => {
  const rawURL = appConfig.BLACKFIN_API_URL;
  const fracturedURL = rawURL.split(".");
  const regionPart = fracturedURL[1];
  if (regionPart === "us") {
    fracturedURL[1] = region;
    return fracturedURL.join(".");
  }
  return appConfig.BLACKFIN_API_URL;
};

export const blackfinApi = region => {
  return Axios.create({
    baseURL: regionalURL(region),
    headers: {
      // eslint-disable-next-line prettier/prettier
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const setDemystConsoleApiUrl = apiAddress => {
  appConfig.DEMYST_CONSOLE_API_URL = apiAddress;
  demystConsoleApi.defaults.baseURL = apiAddress;
  window.localStorage.setItem("demystConsoleApiUrl", apiAddress);
};

export const setBlackfinApiUrl = apiAddress => {
  appConfig.BLACKFIN_API_URL = apiAddress;
  // blackfinApi.defaults.baseURL = apiAddress;
  window.localStorage.setItem("blackfinApiUrl", apiAddress);
};
