import { getDefaultMiddleware, combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import allUseCasesReducer from "./useCases/allUseCasesSlice";
import evaluationUseCasesReducer from "./useCases/evaluationUseCasesSlice";
import productionUseCasesReducer from "./useCases/productionUseCasesSlice";
import createdUseCasesReducer from "./useCases/createdUseCasesSlice";
import singleUseCaseReducer from "./useCases/singleUseCaseSlice";
import settingsReducer from "./settings/settingsSlice";
import projectsReducer from "./projects/projectsSlice";
import singleProjectReducer from "./projects/singleProjectSlice";
import internalDataReducer from "./internalData/internalDataSlice";
import productsCatalogReducer from "./productsCatalog/productsCatalogSlice";
import productsFeaturedCatalogReducer from "./productsCatalog/productsFeaturedCatalogSlice";
import apiTemplatesCatalogReducer from "./apiTemplatesCatalog/apiTemplatesCatalogSlice";
import apiFeaturedTemplatesCatalogReducer from "./apiTemplatesCatalog/apiFeaturedTemplatesCatalogSlice";

const rootReducer = combineReducers({
  user: userReducer,
  allUseCases: allUseCasesReducer,
  evaluationUseCases: evaluationUseCasesReducer,
  productionUseCases: productionUseCasesReducer,
  createdUseCases: createdUseCasesReducer,
  singleUseCase: singleUseCaseReducer,
  settings: settingsReducer,
  projects: projectsReducer,
  singleProject: singleProjectReducer,
  internalData: internalDataReducer,
  productsCatalog: productsCatalogReducer,
  productsFeaturedCatalog: productsFeaturedCatalogReducer,
  apiTemplatesCatalog: apiTemplatesCatalogReducer,
  apiFeaturedTemplatesCatalog: apiFeaturedTemplatesCatalogReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })],
});

export const generateStore = preloadedState =>
  configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })],
    preloadedState,
  });

export default store;
