import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils/serializeError";
import { productsCatalogApi } from "../../api/productsCatalogApi";
import Axios from "axios";

const initialState = {
  productsFeatured: {
    fetchStatus: "IDLE",
    error: null,
    data: {
      entities: {},
      ids: [],
    },
  },
};

// --- THUNKS START ---
const fetchProductsFeaturedCatalog = createAsyncThunk(
  "catalog/fetchProductsFeaturedCatalog",
  async (payload, thunkAPI) => {
    const { productsFeaturedCatalog } = thunkAPI.getState();

    const source = Axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });

    // Data should be fetched only if there are no productsFeatured
    const shouldFetch = productsFeaturedCatalog.productsFeatured.data.ids.length === 0;

    if (shouldFetch) {
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(productsFeaturedCatalogActions.setProductsFeaturedTemplatesLoading());
      }
      const { data } = await productsCatalogApi.getFeaturedProducts({
        cancelToken: source.token,
      });
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(productsFeaturedCatalogActions.setProductsFeaturedTemplates(data));
      }
    }
  },
  {
    serializeError: serializeError,
  },
);

export const productsFeaturedCatalogThunks = {
  fetchProductsFeaturedCatalog,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "productsFeaturedCatalog",
  initialState,
  reducers: {
    setProductsFeaturedTemplates(state, action) {
      const productTemplates = action.payload.providers;
      // set data
      state.productsFeatured.data.entities = {};
      state.productsFeatured.data.ids = [];
      productTemplates.forEach(productTemplate => {
        state.productsFeatured.data.entities[productTemplate.id] = productTemplate;
        state.productsFeatured.data.ids.push(productTemplate.id);
      });
    },
    setProductsFeaturedTemplatesLoading(state) {
      state.productsFeatured.fetchStatus = "PENDING";
      state.productsFeatured.error = null;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: {
    [fetchProductsFeaturedCatalog.fulfilled]: state => {
      state.productsFeatured.fetchStatus = "SUCCESS";
      state.productsFeatured.error = null;
    },
    [fetchProductsFeaturedCatalog.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        return;
      }
      state.productsFeatured.fetchStatus = "FAILED";
      state.productsFeatured.error = action.error;
    },
  },
});

export const productsFeaturedCatalogActions = {
  ...actions,
};

export default reducer;
