import { useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import DOMPurify from "isomorphic-dompurify";

const getUrlString = path => {
  if (typeof path === "string") return path;
  const { pathname, search } = path;
  const startsWithSign = search?.startsWith("?");
  return `${pathname}` + `${search ? `${startsWithSign ? "" : "?"}${search}` : ""}`;
};

const usePathChange = () => {
  const [pathname, setPathname] = useState("");
  const debouncedPathname = useDebounce(pathname, 10);

  function eventHandler(e) {
    if (typeof e?.detail?.pathname !== "undefined" && e?.detail?.pathname !== pathname) {
      if (typeof e?.detail?.pathname === "object" && e?.detail?.pathname.pathname) {
        setPathname(getUrlString(e?.detail?.pathname));
      } else if (typeof e?.detail?.pathname === "string") setPathname(e?.detail?.pathname);
      return;
    }
    const newPathname = getUrlString(DOMPurify.sanitize(window.location));
    if (newPathname !== pathname) {
      setPathname(newPathname);
    }
  }
  useEffect(() => {
    // Updated wraper on URL change of react router
    try {
      const bodyList = document.querySelector("body");
      setPathname(getUrlString(DOMPurify.sanitize(window.location)));
      let observer = new MutationObserver(eventHandler);
      const config = {
        childList: true,
        subtree: true,
      };
      observer.observe(bodyList, config);
      window.addEventListener("pathchange", eventHandler);
      return () => {
        observer.disconnect();
        window.removeEventListener("pathchange", eventHandler);
      };
    } catch (e) {
      console.log(e, "usePathChange");
    }
  }, []);
  return debouncedPathname;
};

export const pathChanged = pathname =>
  typeof window !== "undefined" &&
  new CustomEvent("pathchange", {
    detail: {
      pathname,
    },
  });

export default usePathChange;
