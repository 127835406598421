import { generateColorByIndex, generateRandomHexColor } from "./generateRandomHexColor";

const defaultData = {
  channels: {},
  providers: {},
};

const initDB = () => {
  if (typeof window === "undefined") return defaultData;
  try {
    const LSMetadata = JSON.parse(window.localStorage.getItem("useCasesMetaDB"));
    if (!LSMetadata) {
      window.localStorage.setItem("useCasesMetaDB", JSON.stringify(defaultData));
      return defaultData;
    } else {
      return LSMetadata;
    }
  } catch (e) {
    window.localStorage.setItem("useCasesMetaDB", JSON.stringify(defaultData));
    return defaultData;
  }
};

class _UseCasesMetaDB {
  constructor() {
    const { channels, providers } = initDB();
    this.channels = channels;
    this.providers = providers;
  }

  reset() {
    const { channels, providers } = defaultData;
    this.channels = channels;
    this.providers = providers;
    window.localStorage.setItem("useCasesMetaDB", JSON.stringify(defaultData));
  }

  addChannelInfo({ id, name, position, color }) {
    const resultColor =
      position === 0
        ? generateColorByIndex(position)
        : this.channels[id]?.color || color || generateRandomHexColor();
    this.channels[id] = {
      id,
      name,
      color: resultColor,
    };
    const LSMetadata = JSON.parse(window.localStorage.getItem("useCasesMetaDB"));
    LSMetadata.channels = this.channels;
    window.localStorage.setItem("useCasesMetaDB", JSON.stringify(LSMetadata));
  }

  getChannelInfo(id) {
    return this.channels[id];
  }

  addProviderInfo({ id, name, alias, color, logo, dataSource }) {
    this.providers[name] = {
      id,
      name,
      alias,
      color: this.providers[name]?.color || color || generateRandomHexColor(),
      logo,
      dataSource,
    };
    const LSMetadata = JSON.parse(window.localStorage.getItem("useCasesMetaDB"));
    LSMetadata.providers = this.providers;
    window.localStorage.setItem("useCasesMetaDB", JSON.stringify(LSMetadata));
  }

  getProviderInfo(name) {
    return this.providers[name];
  }
}

export const UseCasesMetaDB = new _UseCasesMetaDB();
