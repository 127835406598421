import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { serializeError } from "../../utils/serializeError";
import { projectsCatalogApi } from "../../api/projectsCatalogApi";

const initialState = {
  projects: [],
  isProjectsFetching: false,
  projectsFetchingError: null,
};

// --- THUNKS START ---

const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (payload, thunkAPI) => {
    const { data } = await projectsCatalogApi.getProjects();

    thunkAPI.dispatch(
      projectsActions.setProjects({
        projects: data.projects,
      }),
    );
  },
  {
    serializeError: serializeError,
  },
);

export const projectsThunks = {
  fetchProjects,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects(state, action) {
      const { projects } = action.payload;
      state.projects = projects;
    },
  },
  extraReducers: {
    [fetchProjects.pending]: state => {
      state.isProjectsFetching = true;
    },
    [fetchProjects.fulfilled]: state => {
      state.isProjectsFetching = false;
    },
    [fetchProjects.rejected]: (state, action) => {
      state.isProjectsFetching = false;
      state.projectsFetchingError = action.error || null;
    },
  },
});

export const projectsActions = {
  ...actions,
};

export default reducer;
