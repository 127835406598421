import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { operationalDashboardApi } from "../../api/operationalDashboardApi";
import { userThunks } from "../user/userSlice";
import { serializeError } from "../../utils/serializeError";
import {
  useCaseQueryTypesConstants,
  dataProductsMetricsConstants,
  getDefaultChannelId,
  formatAverageResponseTimeDataWithProviders,
  formatTransactionsDataWithProviders,
  formatErrorsWithProviders,
  // formatTransactionsData,
  // formatAverageResponseTimeData,
  // formatErrorsData,
} from "./helpers";
import difference from "lodash.difference";
import { format, sub } from "date-fns";
import { generateColorByIndex } from "../../utils/generateRandomHexColor";
import { batch } from "react-redux";
import Axios from "axios";
import { UseCasesMetaDB } from "../../utils/useCasesMetaDB";
import { toNaturalSentence } from "../../utils/string";
import { notEmptyArray } from "../../utils/helpers";

const initialState = {
  startDate: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd"),
  endDate: format(new Date(), "yyyy-MM-dd"),
  useCases: {
    entities: {},
    liveUseCasesIds: [],
    demoUseCasesIds: [],
  },
  dashboard: {
    pageType: "",
    fetchStatus: "IDLE",
    error: null,
    pagination: {
      totalPages: 10,
      totalAPIs: 10,
      page: 1,
      sort: "",
      direction: "",
    },
    requestId: null,
  },
  activeUseCase: {
    fetchStatus: "IDLE",
    error: null,
    id: null,
    activeChannelsIds: [],
  },
  reports: {
    activeReportId: "cumulative",
    providers: {
      entities: {},
      names: [],
    },
  },
  dataProducts: {
    fetchStatus: "IDLE",
    error: null,
    providers: {
      entities: {},
      names: [],
    },
    metrics: {
      [dataProductsMetricsConstants.matchRate]: {
        expanded: false,
        perDayData: [],
        perProviderData: {},
      },
      [dataProductsMetricsConstants.transactions]: {
        expanded: false,
        perDayData: [],
        perProviderData: {},
        metadata: {
          domain: {
            min: 0,
            max: 10,
          },
        },
      },
      [dataProductsMetricsConstants.averageResponseTime]: {
        expanded: false,
        perDayData: [],
        perProviderData: {},
        metadata: {
          domain: {
            min: 0,
            max: 10,
          },
        },
      },
      [dataProductsMetricsConstants.errors]: {
        expanded: false,
        perDayData: [],
        perProviderData: {},
        metadata: {
          domain: {
            min: 0,
            max: 10,
          },
        },
      },
    },
  },
};

// --- THUNKS START ---
const fetchUseCases = createAsyncThunk(
  "useCases/fetchUseCases",
  async (payload, thunkAPI) => {
    const { useCases } = thunkAPI.getState();

    const source = Axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });

    // validate all params from qs (currently, it's only pagination related so for now
    // params === pagination);
    const queryStringParams = {
      page: payload?.page || initialState.dashboard.pagination.page,
      sort: payload?.sort || initialState.dashboard.pagination.sort,
      direction: payload?.direction || initialState.dashboard.pagination.direction,
    };

    // Data should be fetched only when params are different from what's already in Redux
    // or if there are no use cases
    const shouldFetch =
      payload.origin !== useCases.dashboard.pageType ||
      Object.entries(queryStringParams).some(entry => {
        return entry[1]?.toString() !== useCases.dashboard.pagination[entry[0]]?.toString();
      }) ||
      [...useCases.useCases.liveUseCasesIds, ...useCases.useCases.demoUseCasesIds].length === 0 ||
      useCases.dashboard.fetchStatus === "IDLE" ||
      useCases.dashboard.fetchStatus === "FAILED";
    if (shouldFetch) {
      thunkAPI.dispatch(useCasesActions.setUseCasesDashboardType(payload.origin));
      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(useCasesActions.setUseCasesDashboardLoading());
        thunkAPI.dispatch(useCasesActions.setUseCasesDashboardSearchParams(queryStringParams));
      }

      const fetchFuntion = type => {
        if (type === "all") {
          return operationalDashboardApi.getUseCases(queryStringParams, {
            cancelToken: source.token,
          });
        }
        if (type === "production") {
          return operationalDashboardApi.getProductionUseCases(queryStringParams, {
            cancelToken: source.token,
          });
        }
        if (type === "evaluation") {
          return operationalDashboardApi.getEvaluationUseCases(queryStringParams, {
            cancelToken: source.token,
          });
        }
        if (type === "created") {
          return operationalDashboardApi.getCreatedUseCases(queryStringParams, {
            cancelToken: source.token,
          });
        }
      };

      const { data, headers } = await fetchFuntion(payload.origin);

      if (!thunkAPI.signal.aborted) {
        thunkAPI.dispatch(
          useCasesActions.setUseCases({
            data,
            pagination: JSON.parse(headers["x-pagination"]),
          }),
        );
      }
    }
  },
  {
    serializeError: serializeError,
  },
);

const fetchSingleUseCase = createAsyncThunk(
  "useCases/fetchSingleUseCase",
  async (payload, thunkAPI) => {
    const { useCaseId } = payload;
    // const source = Axios.CancelToken.source();
    // thunkAPI.signal.addEventListener("abort", () => {
    //   source.cancel();
    // });

    const { data } = await operationalDashboardApi.getUseCase(useCaseId);
    thunkAPI.dispatch(useCasesActions.addUseCase({ data }));
    // if (!thunkAPI.signal.aborted) {
    //   thunkAPI.dispatch(
    //     useCasesActions.addUseCase({ data })
    //   );
    // }

    // if (!thunkAPI.signal.aborted) {
    //   thunkAPI.dispatch(useCasesActions.setUseCasesDashboardLoading());
    //   thunkAPI.dispatch(useCasesActions.setUseCasesDashboardSearchParams(queryStringParams));
    // }
    // const { data, headers } = await operationalDashboardApi.getUseCases(queryStringParams, {
    //   cancelToken: source.token,
    // });
    // if (!thunkAPI.signal.aborted) {
    //   thunkAPI.dispatch(
    //     useCasesActions.setUseCases({
    //       data,
    //       pagination: JSON.parse(headers["x-pagination"]),
    //     }),
    //   );
    // }
  },
  {
    serializeError: serializeError,
  },
);

const fetchDataProductsData = createAsyncThunk(
  "useCases/fetchDataProductsData",
  async (payload, thunkAPI) => {
    const { dateRange, activeChannelsIds } = payload;

    const source = Axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });

    const {
      data: { data: transactionsRawData, providers },
    } = await operationalDashboardApi.getQueryData(
      {
        startDate: dateRange[0],
        endDate: dateRange[1],
        queryType: useCaseQueryTypesConstants.transactionsAndHitCountsByProvider,
        channelsIds: activeChannelsIds,
        withProviders: true,
      },
      {
        cancelToken: source.token,
      },
    );

    const { data: averageResponseTimeRawData } = await operationalDashboardApi.getQueryData(
      {
        startDate: dateRange[0],
        endDate: dateRange[1],
        queryType: useCaseQueryTypesConstants.averageResponseTimeByProvider,
        channelsIds: activeChannelsIds,
      },
      {
        cancelToken: source.token,
      },
    );

    const { data: errorsRawData } = await operationalDashboardApi.getQueryData(
      {
        startDate: dateRange[0],
        endDate: dateRange[1],
        queryType: useCaseQueryTypesConstants.errorsByProviders,
        channelsIds: activeChannelsIds,
      },
      {
        cancelToken: source.token,
      },
    );

    // Filtering out providers with no name
    const filteredProviders = Object.assign(
      {},
      ...Object.keys(providers).map(provider => {
        if (provider) {
          return {
            [provider]: providers[provider],
          };
        }
      }),
    );

    // This part is done only once. Providers list stays the same, whatever the query type,
    // so we populate the metaDB and providers list only once.
    Object.values(filteredProviders).forEach(providerMetadata => {
      UseCasesMetaDB.addProviderInfo({
        id: providerMetadata.id,
        name: providerMetadata.name,
        alias: providerMetadata.alias || toNaturalSentence(providerMetadata.name),
        logo: providerMetadata.logo,
        dataSource: {
          id: providerMetadata.data_source?.id || "",
          name: providerMetadata.data_source?.name || "",
        },
      });
    });

    thunkAPI.dispatch(useCasesActions.setDataProductsProviders({ providers: filteredProviders }));
    const {
      matchRatePerDayData,
      matchRatePerProviderData,
      transactionsPerDayData,
      transactionsPerProviderData,
      transactionsMetadata,
    } = formatTransactionsDataWithProviders({
      transactionsRawData,
      providers: filteredProviders,
      startDate: dateRange[0],
      endDate: dateRange[1],
    });
    const {
      averageResponseTimePerDayData,
      averageResponseTimePerProviderData,
      averageResponseTimeMetadata,
    } = formatAverageResponseTimeDataWithProviders({
      averageResponseTimeRawData,
      providers: filteredProviders,
      startDate: dateRange[0],
      endDate: dateRange[1],
    });
    const { errorsPerDayData, errorsPerProviderData, errorsMetadata } = formatErrorsWithProviders({
      errorsRawData,
      providers: filteredProviders,
      startDate: dateRange[0],
      endDate: dateRange[1],
    });
    batch(() => {
      thunkAPI.dispatch(
        useCasesActions.setDataProductsMetricData({
          perDayData: matchRatePerDayData,
          perProviderData: matchRatePerProviderData,
          metricType: dataProductsMetricsConstants.matchRate,
        }),
      );
      thunkAPI.dispatch(
        useCasesActions.setDataProductsMetricData({
          perDayData: transactionsPerDayData,
          perProviderData: transactionsPerProviderData,
          metadata: transactionsMetadata,
          metricType: dataProductsMetricsConstants.transactions,
        }),
      );
      thunkAPI.dispatch(
        useCasesActions.setDataProductsMetricData({
          perDayData: averageResponseTimePerDayData,
          perProviderData: averageResponseTimePerProviderData,
          metadata: averageResponseTimeMetadata,
          metricType: dataProductsMetricsConstants.averageResponseTime,
        }),
      );
      thunkAPI.dispatch(
        useCasesActions.setDataProductsMetricData({
          perDayData: errorsPerDayData,
          perProviderData: errorsPerProviderData,
          metadata: errorsMetadata,
          metricType: dataProductsMetricsConstants.errors,
        }),
      );
    });
  },

  {
    serializeError: serializeError,
  },
);

export const useCasesThunks = {
  fetchUseCases,
  // fetchReportsData,
  fetchDataProductsData,
  fetchSingleUseCase,
};
// --- THUNKS END ---

const { actions, reducer } = createSlice({
  name: "useCases",
  initialState,
  reducers: {
    // --- DASHBOARD
    setUseCases(state, action) {
      const { data, pagination } = action.payload;
      // state.useCases.entities = {};
      state.useCases.demoUseCasesIds = [];
      state.useCases.liveUseCasesIds = [];
      state.useCases.archivedIds = [];
      state.useCases.archived = [];
      if (notEmptyArray(data)) {
        data.forEach(useCase => {
          if (useCase.active === true) {
            state.useCases.entities[useCase.id] = useCase;
            if (useCase.demo) {
              state.useCases.demoUseCasesIds.push(useCase.id);
            } else {
              state.useCases.liveUseCasesIds.push(useCase.id);
            }
          } else {
            state.useCases.archivedIds.push(useCase.id);
            state.useCases.archived[useCase.id] = useCase;
          }
        });
        // all use cases need to be sorted by `updated_at`
        const sortingFunction = (a, b) => {
          let dateA = new Date(state.useCases.entities[a].updated_at);
          let dateB = new Date(state.useCases.entities[b].updated_at);
          if (dateA < dateB) return 1;
          if (dateA > dateB) return -1;
          return 0;
        };
        state.useCases.demoUseCasesIds = state.useCases.demoUseCasesIds.sort(sortingFunction);
        state.useCases.liveUseCasesIds = state.useCases.liveUseCasesIds.sort(sortingFunction);
        // set pagination params
        state.dashboard.pagination.page = pagination.current_page;
        state.dashboard.pagination.totalPages = pagination.total_pages;
        state.dashboard.pagination.totalAPIs = pagination.total_entries;
      }
    },
    setUseCasesDashboardLoading(state) {
      state.dashboard.fetchStatus = "PENDING";
      state.dashboard.error = null;
    },
    setUseCasesDashboardType(state, action) {
      state.dashboard.pageType = action.payload;
    },
    // currently, we only set page via params, so for now params === pagination.
    // In the future, this action will be used to set values of all query string params
    setUseCasesDashboardSearchParams(state, action) {
      const { page, sort, direction } = action.payload;
      state.dashboard.pagination.page = page;
      state.dashboard.pagination.sort = sort;
      state.dashboard.pagination.direction = direction;
    },
    setUseCaseDefaultChannelQueriesData(state, action) {
      const { useCaseId, data, providersLogos } = action.payload;
      const useCase = state.useCases.entities[useCaseId];
      if (useCase) {
        useCase.defaultChannelQueriesData = data;
        useCase.defaultChannelQueriesDataCacheTimestamp = new Date().getTime();
        if (providersLogos) {
          useCase.defaultChannelQueriesDataProvidersLogos = providersLogos;
        }
      }
    },
    // --- USE CASE
    addUseCase(state, action) {
      const { data: useCase } = action.payload;
      state.useCases.entities[useCase.id] = useCase;
      if (useCase.demo) {
        state.useCases.demoUseCasesIds = [
          ...new Set([...state.useCases.demoUseCasesIds, useCase.id]),
        ];
      } else {
        state.useCases.liveUseCasesIds = [
          ...new Set([...state.useCases.liveUseCasesIds, useCase.id]),
        ];
      }
      const channels = useCase.channels;
      channels?.forEach((channel, index) => {
        const position = channel.position ?? index;
        UseCasesMetaDB.addChannelInfo({
          id: channel.id,
          name: channel.aka || channel.name,
          position,
          color: generateColorByIndex(channel.position),
        });
      });
    },
    updateUseCase(state, action) {
      const { useCaseId, data } = action.payload;
      const useCaseToUpdate = state.useCases.entities[useCaseId];
      if (useCaseToUpdate) {
        state.useCases.entities[useCaseId] = data;
      }
    },
    deleteUseCase(state, action) {
      const { useCaseId } = action.payload;
      const useCaseToRemove = state.useCases.entities[useCaseId];
      if (useCaseToRemove) {
        delete state.useCases.entities[useCaseId];
        state.useCases.liveUseCasesIds = state.useCases.liveUseCasesIds.filter(
          id => id !== useCaseId,
        );
        state.useCases.demoUseCasesIds = state.useCases.demoUseCasesIds.filter(
          id => id !== useCaseId,
        );
      }
    },
    setActiveUseCaseId(state, action) {
      const { useCaseId } = action.payload;
      state.activeUseCase.id = useCaseId;

      const activeUseCase = state.useCases.entities[useCaseId];
      if (activeUseCase) {
        const channels = activeUseCase.channels;
        channels?.forEach((channel, index) => {
          const position = channel.position ?? index;
          UseCasesMetaDB.addChannelInfo({
            id: channel.id,
            name: channel.aka || channel.name,
            position,
            color: generateColorByIndex(channel.position),
          });
        });
      }
    },
    setActiveChannelsIds(state, action) {
      const { channelsIds } = action.payload;
      const activeUseCase = state.useCases.entities[state.activeUseCase.id];
      if (activeUseCase?.channels) {
        const defaultChannelId = getDefaultChannelId(activeUseCase.channels);
        const activeUseCaseChannelsIds = activeUseCase.channels.map(channel => String(channel.id));
        // if no channels specified, select default one
        if (channelsIds) {
          // Right now, we support selecting either one channel or all available channels.
          // There are two different ways to validate `channelsIds`, depending if user wants to select
          // one channel or more
          if (channelsIds.length > 1) {
            // since we only allow picking all channels (not 2 out of 5 or 3 out of 4) it means that
            // array of all possible channels ids and ids that user wants to choose has to be the same
            const diff = difference(activeUseCaseChannelsIds, channelsIds);
            if (diff?.length === 0) {
              state.activeUseCase.activeChannelsIds = channelsIds;
            } else {
              state.activeuseCase.activeChannelsIds = [defaultChannelId];
            }
          } else {
            // if user wants to pick only one channel we just need to ensure that that channel's id
            // is included in all possible channels ids list of active use case
            state.activeUseCase.activeChannelsIds = activeUseCaseChannelsIds.includes(
              channelsIds[0],
            )
              ? channelsIds
              : [defaultChannelId];
          }
        } else {
          state.activeUseCase.activeChannelsIds = [defaultChannelId];
        }
      }
    },
    selectAllChannels(state) {
      if (state.useCases.entities[state.activeUseCaseId]) {
        state.activeChannelsIds = state.useCases.entities[state.activeUseCaseId].channels.map(
          channel => channel.id,
        );
      }
    },
    updateChannel(state, action) {
      const { useCaseId, channel } = action.payload;
      const useCase = state.useCases.entities[useCaseId];
      if (useCase) {
        const channelToUpdateIndex = useCase.channels.findIndex(c => c.id === channel.id);
        if (channelToUpdateIndex !== -1 && useCase.channels[channelToUpdateIndex]) {
          useCase.channels[channelToUpdateIndex] = {
            ...useCase.channels[channelToUpdateIndex],
            ...channel,
          };
        }
      }
    },
    setDateRange(state, action) {
      const { startDate, endDate } = action.payload;
      try {
        state.startDate = startDate;
        state.endDate = endDate;
      } catch {
        state.startDate = initialState.startDate;
        state.endDate = initialState.endDate;
      }
    },
    // --- REPORTS ---
    resetReportsState(state) {
      state.reports = initialState.reports;
    },
    setActiveReportId(state, action) {
      state.reports.activeReportId = action.payload;
    },
    // --- DATA PRODUCTS ---
    setDataProductsMetricData(state, action) {
      const { perDayData, perProviderData, metadata, metricType } = action.payload;
      state.dataProducts.metrics[metricType].perDayData = perDayData;
      state.dataProducts.metrics[metricType].perProviderData = perProviderData;
      if (metadata) {
        state.dataProducts.metrics[metricType].metadata = metadata;
      }
    },
    setDataProductsProviders(state, action) {
      const { providers } = action.payload;
      state.dataProducts.providers.entities = providers;
      state.dataProducts.providers.names = Object.keys(providers);
    },
    // --- TRANSACTION PANEL ---
    showOverlay(state, action) {
      const { message } = action.payload;
      state.transactionPanel.overlay.show = true;
      state.transactionPanel.overlay.message = message;
    },
    hideOverlay(state) {
      state.transactionPanel.overlay.show = false;
      state.transactionPanel.overlay.message = "";
    },
    toggleEditConfiguration(state) {
      state.transactionPanel.configuration.isEdited =
        !state.transactionPanel.configuration.isEdited;
    },
  },
  extraReducers: {
    [fetchUseCases.fulfilled]: state => {
      state.dashboard.fetchStatus = "SUCCESS";
      state.dashboard.error = null;
    },
    [fetchUseCases.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        return;
      }
      state.dashboard.fetchStatus = "FAILED";
      state.dashboard.error = action.error;
    },
    [fetchSingleUseCase.pending]: state => {
      state.activeUseCase.fetchStatus = "PENDING";
      state.activeUseCase.error = null;
    },
    [fetchSingleUseCase.fulfilled]: state => {
      state.activeUseCase.fetchStatus = "SUCCESS";
      state.activeUseCase.error = null;
    },
    [fetchSingleUseCase.rejected]: (state, action) => {
      state.activeUseCase.fetchStatus = "FAILED";
      state.activeUseCase.error = action.error;
    },
    [fetchDataProductsData.pending]: state => {
      state.dataProducts.fetchStatus = "PENDING";
      state.dataProducts.error = null;
    },
    [fetchDataProductsData.fulfilled]: state => {
      state.dataProducts.fetchStatus = "SUCCESS";
      state.dataProducts.error = null;
    },
    [fetchDataProductsData.rejected]: (state, action) => {
      if (action?.error?.name === "AbortError") {
        return;
      }
      state.dataProducts.fetchStatus = "FAILED";
      state.dataProducts.error = action.error;
    },
    [userThunks.logoutUser.fulfilled]: () => {
      return initialState;
    },
  },
});

export const useCasesActions = {
  ...actions,
};

export default reducer;
