import { createGlobalStyle } from "styled-components";

import { useEffect } from "react";
import GlobalStyle from "@demystdata/styles/global";
import AppErrorBoundary from "../src/platform/components/AppErrorBoundary/AppErrorBoundary";
import { linksConstants } from "../src/constants/linksConstants";
import dynamic from "next/dynamic";
const DevTools = dynamic(() => import("../src/platform/layout/DevTools").then(mod => mod.DevTools));

import useScrollToTop from "../src/landingPage/layouts/LayoutComponents/ScrollToTop";
import usePathChange from "../src/utils/hooks/usePathChange";
import Head from "next/head";

import Provider from "../src/redux/Provider";
import { Wrappers } from "../src/landingPage/Wrappers";
import { UserTracking } from "../src/config/userTracking";
import { NonceProvider } from "../src/context/NonceContext";

import "../src/styles/global.less";

const GlobalStyleOverride = createGlobalStyle`
  a {
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  body {
    color: #111827;
  }
`;

function App({ Component, pageProps = {}, nonce }) {
  const pathname = usePathChange();

  useScrollToTop(pathname);

  useEffect(() => {
    const isPlatform = pathname.startsWith("/app/");
    const isEmptyPage = [
      linksConstants.LOGIN,
      linksConstants.SIGN_UP,
      linksConstants.UNLOCK_ACCOUNT,
      linksConstants.CONFIRM,
      linksConstants.RESET_PASSWORD,
      linksConstants.ACCEPT_INVITATION,
      linksConstants.CHANGE_PASSWORD,
      linksConstants.TOS_ACCEPTANCE,
      linksConstants.FORGOT_PASSWORD,
    ];
    const hasPlatformStyling = isPlatform || isEmptyPage.includes(pathname);
    if (pathname) {
      document.querySelector("body").className = hasPlatformStyling ? "" : "customFont";
    }
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
    });
  }, []);

  const Wrapper = Wrappers[pageProps?.wrapper || "NoWrapper"];

  return (
    <Provider.User>
      <Head nonce={nonce}>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>Demyst: The External Data Management Platform</title>
      </Head>
      <GlobalStyle />
      <GlobalStyleOverride />
      <AppErrorBoundary>
        <Wrapper>
          <NonceProvider nonce={nonce}>
            <Component className="App" {...pageProps} />
          </NonceProvider>
        </Wrapper>
      </AppErrorBoundary>
      <DevTools />
      <UserTracking />
    </Provider.User>
  );
}
App.getInitialProps = async appContext => {
  const { ctx, Component } = appContext;
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const nonce = ctx.res ? ctx.res.getHeader("x-nonce") : null;

  return { pageProps, nonce };
};
export default App;
